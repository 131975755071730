import { FC } from 'react';
import { IconProps } from './types';

const PureVuImageViewIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 160 120"
      className={className}
    >
      {/* Path with cls-3 styles */}
      <path
        d="M58.24,66.19l7.24-6.08c1.21-1.01,2.97-1,4.16.04l6.35,5.54c.32.28.79.26,1.09-.04l11.17-11.17c1.34-1.35,3.56-1.23,4.75.25l9.23,11.45,1.49,1.78v6.54c0,.44-.36.53-.81.53h-45.87c-.44,0-.79-.09-.8-.53v-6.85l1.98-1.47Z"
        fill="rgba(84, 196, 94, 0.08)"
        fillRule="evenodd"
      />

      {/* Path with cls-2 styles */}
      <path
        d="M48.4,25.8h63.2c1.21,0,2.2.98,2.2,2.2v64c0,1.22-.99,2.2-2.2,2.2h-63.2c-1.22,0-2.2-.99-2.2-2.2V28c0-1.22.98-2.2,2.2-2.2Z"
        stroke="#7fd387"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />

      {/* Path with cls-2 styles */}
      <path
        d="M54.8,33.02h50.4c.77,0,1.4.63,1.4,1.4v42.4c0,.77-.63,1.4-1.4,1.4h-50.4c-.77,0-1.4-.63-1.4-1.4v-42.4c0-.77.63-1.4,1.4-1.4Z"
        stroke="#7fd387"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />

      {/* Circle with cls-2 styles */}
      <circle
        cx="67.5"
        cy="45.01"
        r="4.8"
        stroke="#7fd387"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />

      {/* Path with cls-1 styles */}
      <path
        d="M56.74,67.86c2.9-2.49,8.71-7.48,8.71-7.48,1.21-1.01,2.97-1,4.16.04l6.35,5.54c.32.28.79.26,1.09-.04l11.17-11.17c1.25-1.25,3.28-1.25,4.53,0,.08.08.16.17.23.25,0,0,8.33,10.57,10.27,13.04"
        stroke="#7fd387"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

export default PureVuImageViewIcon;