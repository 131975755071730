import { useState, useRef, useEffect } from 'react';
import { cn } from 'utils';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import { CustomSelectProps } from './types';

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  value,
  onChange,
  className,
  isDisabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownAbove, setIsDropdownAbove] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownMaxHeight = 240;

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setIsDropdownAbove(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle window resize when dropdown is open
  useEffect(() => {
    const handleResize = () => {
      if (isOpen && buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - rect.bottom;
        const spaceAbove = rect.top;

        if (spaceBelow < dropdownMaxHeight && spaceAbove > dropdownMaxHeight) {
          setIsDropdownAbove(true);
        } else {
          setIsDropdownAbove(false);
        }
      }
    };

    if (isOpen) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen]);

  const handleToggleDropdown = () => {
    if (!isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;
      const dropdownHeight = 240; // max-h-60 in Tailwind is 15rem = 240px

      if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
        setIsDropdownAbove(true);
      } else {
        setIsDropdownAbove(false);
      }
    }
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={cn('relative inline-block text-left', className)}
      ref={dropdownRef}
    >
      <div>
        <button
          type="button"
          ref={buttonRef}
          className={cn(
            "inline-flex justify-between items-center w-full pl-3 pr-0.5 py-1 bg-table-number-selected border border-border-table",
            "rounded cursor-pointer focus:outline-none focus:ring-2 focus:ring-[#54C45E] gap-3",
            isDisabled ? 'opacity-50 cursor-default' : ''
          )}
          onClick={handleToggleDropdown}
          disabled={isDisabled}
        >
          <span>{value}</span>
          <ChevronDownIcon className="w-3 h-3" />
        </button>
      </div>

      {/* Dropdown Menu */}
      {isOpen && (
        <ul
          style={{ maxHeight: `${dropdownMaxHeight}px` }}
          className={cn(
            "absolute z-10 w-full bg-bg-secondary border border-border-table rounded-md shadow-lg overflow-auto focus:outline-none",
            isDropdownAbove ? "bottom-full mb-0.5" : "mt-0.5"
          )}
          role="listbox"
        >
          {options.map((option, index) => (
            <li
              key={index}
              className={cn(
                'cursor-pointer select-none relative hover:bg-bg-tertiary pl-2 py-1.5',
                option === value ? 'bg-bg-tertiary' : ''
              )}
              onClick={() => {
                onChange(option);
                setIsOpen(false);
                setIsDropdownAbove(false);
              }}
              role="option"
              aria-selected={option === value}
            >
              <span
                className={cn(
                  'block truncate ',
                  option === value ? 'font-semibold' : 'font-normal'
                )}
              >
                {option}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;