import { useState, useEffect, useRef, useMemo } from 'react';
import { cn } from 'utils';
import Button from 'components/common/Button';
import PhotoIcon from 'components/common/Icons/PhotoIcon';
import PureVuUploadIcon from 'components/common/Icons/PureVuUploadIcon';
import DeleteIcon from 'components/common/Icons/DeleteIcon';
import AddIcon from 'components/common/Icons/AddIcon';
import MinusIcon from 'components/common/Icons/MinusIcon';
import CloseIcon from 'components/common/Icons/CloseIcon';
import ImageWithZoom from 'components/common/ImageWithZoom';
import { useAtom } from 'jotai';
import currentModalsAtom, { CurrentModal } from 'atoms/currentModalsAtom';
import { modalNames } from 'utils/cachingConfig';
import PureVuErrorIcon from 'components/common/Icons/PureVuErrorIcon';
import NoSymbol from 'components/common/Icons/NoSymbol';

const FileUpload = () => {
  const [files, setFiles] = useState<any>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const supportedFileTypes = useMemo(() => ['.png', '.jpeg', '.jpg', '.webp', '.bitmap', '.gif', '.tiff'], []);
  const fileSizeLimit = 10 * 1024 * 1024; // 10MB
  const [errors, setErrors] = useState<string[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [scale, setScale] = useState(1);
  const [, setCurrentModals] = useAtom(currentModalsAtom);

  // Handlers for zooming
  const handleZoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom at 3x
  const handleZoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom at 0.5x
  const handleResetZoom = () => setScale(1);

  const handleClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = supportedFileTypes.join(',');
    input.multiple = false;
    input.onchange = (e: any) => {
      setErrors([]);
      const filesArray = Array.from(e.target.files);
      filesArray.forEach((file: any) => {
        if (file.size > fileSizeLimit) {
          setErrors((prevErrors: any) => [...prevErrors, 'size']);
        } else {
          setFiles((prevFiles: any) => [...prevFiles, file]);
        }
      });
    };
    input.click();
  };

  useEffect(() => {
    const handleDragEnter = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDragOver = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDragLeave = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      // Only set isDragging to false when the mouse is outside the window
      if (e.screenX === 0 && e.screenY === 0) {
        setIsDragging(false);
      }
    };

    const handleDrop = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      setErrors([]);
      if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
        const droppedFiles = Array.from(e.dataTransfer.files);

        droppedFiles.forEach((file: any) => {
          const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`;
          if (file.size > fileSizeLimit) {
            setErrors((prevErrors: any) => [...prevErrors, 'size']);
          } else if (!supportedFileTypes.includes(fileExtension)) {
            setErrors((prevErrors: any) => [...prevErrors, 'type']);
          } else {
            setFiles((prevFiles: any) => [...prevFiles, file]);
          }
        });
      };
    }

    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('drop', handleDrop);
    };
  }, [fileSizeLimit, supportedFileTypes]);

  useEffect(() => {
    const errorToModalMap: Record<string, CurrentModal> = {
      size: {
        id: modalNames.imageCleanerSizeError,
        component: SizeErrorModalContent,
        props: { fileSizeLimit },
      },
      type: {
        id: modalNames.imageCleanerFileTypeError,
        component: FileTypeErrorModalContent,
        props: { supportedFileTypes },
      },
      cleaning: {
        id: modalNames.imageCleanerCleaningError,
        component: CleaningErrorModalContent,
      },
    };

    // Filter the map to the ones present in `errors`
    const matchedConfigs = Object.keys(errorToModalMap)
      .filter((key) => errors.includes(key))
      .map((key) => errorToModalMap[key]);

    if (matchedConfigs.length === 0) return;

    // Push each matching modal to currentModals
    setCurrentModals((current) => [...current, ...matchedConfigs]);
  }, [errors, setCurrentModals, supportedFileTypes, fileSizeLimit]);


  const Overlay = () => (
    <div className='fixed p-8 top-0 left-0 w-full h-full bg-bg-overlay z-50 flex items-center justify-center backdrop-blur-sm'>
      <div className='flex flex-col items-center justify-center gap-4 border-4 border-border-overlay border-dashed w-full h-full rounded-xl'>
        <PhotoIcon className='size-24 text-text-tertiary' />
        <p className='text-text-primary text-5xl font-medium text-center'>Drop image here</p>
        <p className='text-text-secondary text-xl font-semibold text-center'>
          All image file supported: ({supportedFileTypes
            .map(type => type.replace('.', '').toUpperCase())
            .join(', ')}).
        </p>
      </div>
    </div>
  );

  return (
    <div className='relative w-full h-full text-center gap-4 flex flex-col justify-between' ref={dropdownRef}>
      {isDragging && <Overlay />}
      <div
        className={cn(
          "flex flex-col items-center transition-[background-color] ease-in-out duration-300 gap-2 w-full rounded-[4px]",
          "cursor-pointer bg-bg-secondary border border-border-secondary h-full max-h-[calc(100%-52px)]",
        )}>
        {files.length > 0 ? (
          <ImageWithZoom
            imgSrcArray={[URL.createObjectURL(files[0])]}
            scale={scale}
            setScale={setScale}
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
            handleResetZoom={handleResetZoom}
            imagePadding='p-0'
          />
        ) : (
          <div
            className='flex flex-col gap-8 items-center justify-center h-full'
            onClick={handleClick}
          >
            <div className='flex flex-col items-center gap-2'>
              <PureVuUploadIcon className='h-48 w-48 mb-[-20px]' />
              <p className='text-2xl font-semibold text-text-primary'>
                Start by adding an image
              </p>
              <p className='text-text-secondary text-base'>
                All image file supported: ({supportedFileTypes
                  .map(type => type.replace('.', '').toUpperCase())
                  .join(', ')}).
              </p>
            </div>
            <div className='flex flex-col items-center gap-2'>
              <Button type='button' variant='primary' classNames='w-fit'>
                Upload Image
              </Button>
              <p className='text-lg font-semibold text-text-primary'>
                or drag a file here
              </p>
            </div>
          </div>
        )}
      </div>
      <div className='h-9 flex flex-row text-text-primary items-center justify-between gap-2'>
        <Button
          type='button'
          variant='secondary'
          onClick={() => setFiles([])}
          isDisabled={files.length === 0}
          classNames='flex flex-row bg-transparent text-text-primary gap-2 hover:bg-transparent items-center'
        >
          <DeleteIcon className='h-4 w-4' />
          Clear Image
        </Button>
        <div className='flex flex-row gap-4'>
          {scale !== 1 && (
            <div onClick={handleResetZoom}>
              <CloseIcon
                className={cn(
                  'h-9 w-9 text-text-secondary border border-border-main rounded-[4px] p-1.5 cursor-pointer',
                  files.length === 0 && 'opacity-50 cursor-default'
                )}
              />
            </div>
          )}
          <div onClick={handleZoomIn}>
            <AddIcon
              className={cn(
                'h-9 w-9 text-text-secondary border border-border-main rounded-[4px] p-1.5 cursor-pointer',
                files.length === 0 && 'opacity-50 cursor-default'
              )}
            />
          </div>
          <div onClick={handleZoomOut}>
            <MinusIcon
              className={cn(
                'h-9 w-9 text-text-secondary border border-border-main rounded-[4px] p-1.5 cursor-pointer',
                files.length === 0 && 'opacity-50 cursor-default'
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CleaningErrorModalContent = () => {
  const [, setCurrentModals] = useAtom(currentModalsAtom);

  const handleClose = () => {
    setCurrentModals((currentModals) => currentModals.filter((modal) => modal.id !== modalNames.imageCleanerCleaningError));
  }

  return (
    <div className='flex flex-col gap-8 items-center justify-center bg-bg-modal border border-border-table rounded-xl p-8 text-center'>
      <PureVuErrorIcon className='h-32 w-auto mb-[-20px]' />
      <div className='flex flex-col items-center justify-center'>
        <p className='text-xl font-semibold text-text-primary'>
          Error cleaning the image
        </p>
        <p className='text-text-secondary'>
          Please try again. If the issue persists, contact us.
        </p>
      </div>
      <Button
        variant='error'
        type='button'
        onClick={handleClose}
      >
        Ok
      </Button>
    </div>
  );
};

const FileTypeErrorModalContent = ({ supportedFileTypes }: { supportedFileTypes: string[] }) => {
  const [, setCurrentModals] = useAtom(currentModalsAtom);

  const handleClose = () => {
    setCurrentModals((currentModals) => currentModals.filter((modal) => modal.id !== modalNames.imageCleanerFileTypeError));
  }

  return (
    <div className='flex flex-row gap-6 items-start justify-center bg-bg-modal border border-border-table rounded-xl p-8 max-w-[500px]'>
      <NoSymbol className='h-10 w-10 text-icon-failed bg-bg-failed p-2 rounded-full min-w-fit' />
      <div className='flex flex-col items-start justify-center gap-8'>
        <div className='flex flex-col items-start justify-center gap-4'>
          <p className='text-xl font-semibold text-text-primary'>
            File type not supported
          </p>
          <p className='text-text-secondary'>
            The image files supported are {supportedFileTypes.map(type => type.replace('.', '').toUpperCase()).join(', ')}.
          </p>
        </div>
        <Button
          variant='error'
          type='button'
          onClick={handleClose}
          classNames='self-end'
        >
          Ok
        </Button>
      </div>
    </div>
  );
};

const SizeErrorModalContent = ({ fileSizeLimit }: { fileSizeLimit: number }) => {
  const [, setCurrentModals] = useAtom(currentModalsAtom);

  const handleClose = () => {
    setCurrentModals((currentModals) => currentModals.filter((modal) => modal.id !== modalNames.imageCleanerSizeError));
  }

  return (
    <div className='flex flex-row gap-6 items-start justify-center bg-bg-modal border border-border-table rounded-xl p-8 max-w-[500px]'>
      <NoSymbol className='h-10 w-10 text-icon-failed bg-bg-failed p-2 rounded-full min-w-fit' />
      <div className='flex flex-col items-start justify-center gap-8'>
        <div className='flex flex-col items-start justify-center gap-4'>
          <p className='text-xl font-semibold text-text-primary'>
            File size too large
          </p>
          <p className='text-text-secondary'>
            The file size should be less than {fileSizeLimit / 1024 / 1024}MB.
          </p>
        </div>
        <Button
          variant='error'
          type='button'
          onClick={handleClose}
          classNames='self-end'
        >
          Ok
        </Button>
      </div>
    </div>
  );
};

export default FileUpload;