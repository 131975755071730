import { cn, getUsage } from "utils"
import RocketIcon from "../Icons/RocketIcon"
import PhotoIcon from "../Icons/PhotoIcon"
import Button from "../Button"
import { useMemo } from "react"
import useCurrentAuthenticatedState from "hooks/useCurrentAuthenticatedState"
import { useNavigate } from "react-router-dom"

const ProductsOverview = () => {
  const [currentState] = useCurrentAuthenticatedState();
  const usage = useMemo(() => getUsage(currentState, 'DocumentIntelligence'), [currentState]);
  const navigate = useNavigate();

  return (
    <div
      className={cn(
        "w-full h-fit-content p-8 flex flex-col gap-8 rounded-xl transition-[background-color] ease-in-out duration-300",
        "bg-[#EAF8EB] dark:bg-[#2B3544] text-text-primary"
      )}
    >
      <p className='text-2xl font-semibold'>Products</p>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
        <div className="border border-border-tertiary dark:border-border-main rounded-xl p-8 flex flex-col gap-4">
          <div className="flex flex-row items-center justify-start gap-4">
            <RocketIcon className='w-10 h-10 text-text-primary p-2 bg-avatar-bg rounded-lg' />
            <p className='text-2xl font-semibold'>TruScan</p>
          </div>
          <p className='text-text-primary'>Description of this tool and what it does</p>
          <div className='flex flex-row items-center justify-between gap-4'>
            <div className='flex flex-row items-center justify-start gap-4'>
              <div className="relative rounded-[100px] p-[2px] min-w-fit">
                <div className="pointer-events-none absolute inset-0 rounded-[100px] bg-gradient-to-r from-[#54C45E] to-[#AAE2AF]" />
                <div className="relative bg-[#EAF8EB] dark:bg-[#2B3544] rounded-[100px] transition-[background-color] ease-in-out duration-300">
                  <p className="uppercase text-xs bg-gradient-to-r from-[#54C45E] to-[#AAE2AF] bg-clip-text text-transparent p-1">
                    Free Trial
                  </p>
                </div>
              </div>
              <div className='flex flex-row items-center justify-start gap-1 min-w-fit'>
                <p className='text-lg text-text-tertiary font-semibold'>
                  {usage.remaining}
                </p>
                <p className='text-sm text-text-secondary text-ellipsis overflow-hidden'>
                  / {usage.limit} remaining
                </p>
              </div>
            </div>
            <Button variant='primary' type="button">Upgrade</Button>
          </div>
        </div>
        <div className="border border-border-tertiary dark:border-border-main rounded-xl p-8 flex flex-col gap-4">
          <div className="flex flex-row items-center justify-start gap-4">
            <PhotoIcon className='w-10 h-10 text-text-primary p-2 bg-avatar-bg rounded-lg' />
            <p className='text-2xl font-semibold'>PureVu</p>
          </div>
          <p className='text-text-primary'>Description of this tool and what it does</p>
          <Button
            classNames='self-start'
            variant='primary'
            type="button"
            onClick={() => navigate('/pure-vu')}
          >
            Try for free
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ProductsOverview