import { FC } from "react";
import { cn } from "utils";

export interface ToggleProps {
  label: string;
  isChecked: boolean;
  isDisabled?: boolean;
}

const Toggle: FC<ToggleProps> = ({ label, isChecked, isDisabled = false }) => {
  return (
    <label className={cn(
      "inline-flex items-center cursor-pointer",
      isDisabled && "opacity-50 cursor-default"
    )}>
      <input type="checkbox" value="" className="sr-only peer" defaultChecked={isChecked} disabled={isDisabled} />
      <div
        className={cn(
          "relative w-11 h-6 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-toggle-hover",
          "rounded-full peer bg-toggle-bg peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full",
          "peer-checked:after:border-toggle-selected after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-toggle-circle",
          "after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-toggle-selected"
        )}
      />
      <span className="ms-3 text-sm font-medium text-text-primary">
        {label}
      </span>
    </label>
  )
}

export default Toggle