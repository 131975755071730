import { FC, Fragment, useEffect, useMemo, useRef, useState } from "react";
import { DetailsBoxProps, ViewInvoiceDetailsProps } from "../../types";
import { InvoiceOverview } from "types/dbSchema/invoices";
import { cn } from "utils";
import ExclamationCircle from "components/common/Icons/ExclamationCircle";
import ExclamationTriangle from "components/common/Icons/ExclamationTriangle";

const InvoiceDetailsTab: FC<ViewInvoiceDetailsProps> = ({ currentInvoice, containerHeight }) => {
  const serviceProviderDetails = useMemo(() => serviceProviderObject(currentInvoice), [currentInvoice]);
  const participantDetails = useMemo(() => participantObject(currentInvoice), [currentInvoice]);
  const invoiceDetails = useMemo(() => invoiceObject(currentInvoice), [currentInvoice]);
  const fileInformationDetails = useMemo(() => fileInformationObject(currentInvoice), [currentInvoice]);

  // Ref to the parent div to determine the flex direction  
  const parentRef = useRef<HTMLDivElement>(null);
  const [flexDirection, setFlexDirection] = useState<"row" | "column">("row");

  useEffect(() => {
    const element = parentRef.current;
    if (!element) return;
    setFlexDirection(element.clientWidth <= 710 ? "column" : "row")
  }, [parentRef?.current?.clientWidth]);

  return (
    <div
      ref={parentRef}
      style={{
        height: `calc(100vh - ${containerHeight + 224}px)`,
        flexDirection,
      }}
      className="flex gap-8 h-full overflow-y-auto mt-6 pr-6 text-text-primary w-full"
    >
      <div className="flex flex-col gap-8 w-full">
        <DetailsBox detailsObject={serviceProviderDetails} />
        <DetailsBox detailsObject={participantDetails} />
      </div>
      <div className="flex flex-col gap-8 w-full">
        <DetailsBox detailsObject={invoiceDetails} />
        <DetailsBox detailsObject={fileInformationDetails} />
      </div>
    </div>
  )
}

const DetailsBox: FC<DetailsBoxProps> = ({ detailsObject }) => {

  return (
    <div className="bg-bg-secondary min-width-[360px] w-full flex flex-col items-start justify-start px-6 border border-border-table rounded-[4px]" >
      {detailsObject.map((section, sectionIndex) => (
        <Fragment key={sectionIndex}>
          <div className="pt-6">
            <p className="text-sm font-semibold text-text-supporting uppercase">{section.title}</p>
          </div>
          <div className="w-full flex flex-col items-start justify-start gap-5">
            {section.items.map((item, itemIndex) => (
              <div
                key={itemIndex}
                className={cn(
                  "w-full flex flex-row items-start justify-start gap-2 py-5",
                  // Add border if it is not the last item in the last section
                  !(
                    sectionIndex === detailsObject.length - 1 &&
                    itemIndex === section.items.length - 1
                  ) && "border-b border-border-table"
                )}
              >
                <p className={cn(
                  "text-sm font-normal w-[140px] min-w-[140px]",
                  item.isValid ? "text-text-primary" :
                    item.isIntegrityCheck ? "text-text-mismatch" : "text-text-incomplete"
                )}>
                  {item.label}
                </p>
                {/* If valid, show value */}
                {item.isValid ? (
                  <p className="text-sm font-normal text-text-primary">
                    {item.value}
                  </p>
                ) : item.isIntegrityCheck ? (
                  // If invalid & integrity check, show warning icon
                  <div className="w-full flex flex-row items-start justify-between gap-2">
                    <p className="text-sm font-semibold text-text-mismatch">
                      {item.value ? item.value : `Not Found`}
                    </p>
                    <ExclamationTriangle className="text-text-mismatch h-5 w-5" />
                  </div>
                ) : (
                  // If invalid & not integrity check, show error icon
                  <div className="w-full flex flex-row items-start justify-between gap-2">
                    <p className="text-sm font-semibold text-text-incomplete">
                      {item.value ? item.value : `Not Found`}
                    </p>
                    <ExclamationCircle className="text-text-incomplete h-5 w-5" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  )
}

const serviceProviderObject = (currentInvoice: InvoiceOverview) => ([
  {
    title: "Service Provider",
    items: [
      {
        label: "Name",
        value: currentInvoice?.invoice.providerName,
        isIntegrityCheck: false,
        isValid: currentInvoice.validation.providerName,
      },
      {
        label: "ABN",
        value: currentInvoice?.invoice.providerAbn,
        isIntegrityCheck: false,
        isValid: currentInvoice.validation.providerAbn,
      },
      {
        label: "Address",
        value: currentInvoice?.invoice.providerAddress,
        isIntegrityCheck: false,
        // isValid: currentInvoice.invoice.providerAddress ? true : false,
        isValid: true,
      }
    ]
  },
  {
    title: "Bank Details",
    items: [
      {
        label: "Account Name",
        value: currentInvoice?.invoice.providerBankAccountName,
        isIntegrityCheck: false,
        // isValid: currentInvoice?.invoice.providerBankAccountName ? true : false,
        isValid: true,
      },
      {
        label: "BSB",
        value: currentInvoice?.invoice.providerBsb,
        isIntegrityCheck: false,
        isValid: currentInvoice.invoice.providerBsb ? true : false,
      },
      {
        label: "Account Number",
        value: currentInvoice?.invoice.providerBankAccount,
        isIntegrityCheck: false,
        isValid: currentInvoice.invoice.providerBankAccount ? true : false,
      }
    ]
  }
])

const participantObject = (currentInvoice: InvoiceOverview) => ([
  {
    title: "Participant",
    items: [
      {
        label: "Name",
        value: currentInvoice?.invoice.participantName,
        isIntegrityCheck: false,
        isValid: currentInvoice.validation.participantName,
      },
      {
        label: "Number",
        value: currentInvoice?.invoice.participantNumber,
        isIntegrityCheck: false,
        isValid: currentInvoice.validation.participantNumber,
      }
    ]
  }
])

const invoiceObject = (currentInvoice: InvoiceOverview) => ([
  {
    title: "Invoice",
    items: [
      {
        label: "Number",
        value: currentInvoice?.invoice.invoiceNumber,
        isIntegrityCheck: false,
        isValid: currentInvoice.validation.invoiceNumber,
      },
      {
        label: "Date",
        value: currentInvoice?.invoice.invoiceDate,
        isIntegrityCheck: false,
        isValid: currentInvoice.validation.invoiceDate,
      },
      {
        label: "Total",
        value: currentInvoice?.invoice.total,
        isIntegrityCheck: false,
        isValid: currentInvoice.validation.total,
      }
    ]
  }
])

const fileInformationObject = (currentInvoice: InvoiceOverview) => ([
  {
    title: "File Information",
    items: [
      {
        label: "File Name",
        value: currentInvoice?.storedFile.fileName,
        isIntegrityCheck: false,
        isValid: true,
      },
      {
        label: "File Type",
        value: currentInvoice?.storedFile.mediaType,
        isIntegrityCheck: false,
        isValid: true,
      },
      {
        label: "File Size",
        value: currentInvoice?.storedFile.size,
        isIntegrityCheck: false,
        isValid: true,
      },
      {
        label: "Upload Date",
        value: new Date(currentInvoice?.storedFile.uploadDate).toLocaleDateString(),
        isIntegrityCheck: false,
        isValid: true,
      }
    ]
  }
])

export default InvoiceDetailsTab;