import { cn } from 'utils';
import { useState, useEffect, useRef, FC } from 'react';
import { TabsProps } from './types';

// Tab content will need to handle it's own height, width, padding etc.
const Tabs: FC<TabsProps> = ({ tabs }) => {
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0].label);
  const [indicatorStyles, setIndicatorStyles] = useState<{ left: number; width: number } | null>(null);
  const tabsRef = useRef<Array<HTMLButtonElement | null>>([]);

  useEffect(() => {
    if (selectedTab === null) {
      setIndicatorStyles(null);
      return;
    }

    const currentTab = tabsRef.current.find(
      (tab) => tab?.dataset.label === selectedTab
    );

    if (currentTab) {
      setIndicatorStyles({
        left: currentTab.offsetLeft,
        width: currentTab.offsetWidth,
      });
    } else {
      setIndicatorStyles(null);
    }
  }, [selectedTab]);

  const handleTabChange = (label: string) => setSelectedTab(label);

  return (
    <div className='flex flex-col w-full h-full'>
      <div className="relative border-b border-b-border-secondary w-full flex items-start h-10">
        <div className="flex items-center justify-center h-full">
          {tabs.map((tab, index) => (
            <button
              key={tab.label}
              data-label={tab.label}
              ref={(el) => (tabsRef.current[index] = el)}
              onClick={() => handleTabChange(tab.label)}
              className={cn(
                "relative flex flex-row items-center px-4 py-2 gap-2 transition-colors duration-200",
                selectedTab === tab.label ? "text-nav-selected font-semibold" : "text-text-primary font-medium"
              )}
            >
              {tab.label}
            </button>
          )
          )}
        </div>
        {indicatorStyles && (
          <span
            className="absolute bottom-0 h-1 bg-nav-selected transition-all duration-300"
            style={{
              left: indicatorStyles.left,
              width: indicatorStyles.width,
            }}
          />
        )}
      </div>
      {tabs.map((tab) => {
        if (selectedTab === tab.label) {
          return <tab.content key={tab.label} />;
        }
        return null;
      })}
    </div>
  );
};

export default Tabs;