import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import Providers from 'components/common/Providers';
import { StrictMode } from 'react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <Providers>
      <App />
    </Providers>
  </StrictMode>
);