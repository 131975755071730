import { FC, useEffect, useRef, useState } from "react";
import { cn } from "utils";

export interface ImageSectionProps {
  imgSrcArray: string[];
  scale: number;
  setScale: (value: number) => void;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  handleResetZoom: () => void;
  imagePadding?: string;
}

const ImageWithZoom: FC<ImageSectionProps> = ({ imgSrcArray, scale, handleZoomIn, handleZoomOut, imagePadding }) => {
  const outerContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isPanning, setIsPanning] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [scrollOffset, setScrollOffset] = useState({ x: 0, y: 0 });

  // Mouse down to start panning
  const handleMouseDown = (e: React.MouseEvent) => {
    if (outerContainerRef.current) {
      setIsPanning(true);
      setStartPos({ x: e.clientX, y: e.clientY });
      setScrollOffset({
        x: outerContainerRef.current.scrollLeft,
        y: outerContainerRef.current.scrollTop,
      });
    }
  };

  // Mouse up to stop panning
  const handleMouseUp = () => {
    setIsPanning(false);
  };

  // Mouse move to update scroll position if panning
  const handleMouseMove = (e: React.MouseEvent) => {
    if (isPanning && outerContainerRef.current) {
      const deltaX = e.clientX - startPos.x;
      const deltaY = e.clientY - startPos.y;
      outerContainerRef.current.scrollLeft = scrollOffset.x - deltaX;
      outerContainerRef.current.scrollTop = scrollOffset.y - deltaY;
    }
  };

  useEffect(() => {
    const handleZoom = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        e.deltaY > 0 ? handleZoomOut() : handleZoomIn();
      }
    };

    window.addEventListener('wheel', handleZoom, { passive: false });
    window.addEventListener('mouseup', handleMouseUp); // Stop panning on mouse up

    return () => {
      window.removeEventListener('wheel', handleZoom);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleZoomIn, handleZoomOut]);

  return (
    <div
      ref={outerContainerRef}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      className={cn(
        "flex flex-col items-start justify-start gap-4 overflow-auto relative h-full w-full",
        isPanning ? 'cursor-grabbing' : 'cursor-grab',
        imagePadding
      )}
    >
      <div
        ref={containerRef}
        className="test w-full h-full flex flex-col items-start justify-start gap-4 transition-transform duration-200 origin-top-left min-h-fit"
        style={{
          height: `${100 * scale}%`,
          width: `${100 * scale}%`,
        }}
      >
        {imgSrcArray.map((imgSrc, index) => (
          <img
            key={index}
            src={imgSrc}
            alt="Invoice"
            className='w-full h-auto'
            draggable={false}
          />
        ))}
      </div>
    </div>
  )
}

export default ImageWithZoom