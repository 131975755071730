import { createApiKey, deleteApiKey } from "api/apikey";
import currentModalsAtom from "atoms/currentModalsAtom";
import { invalidateDataAtom } from "atoms/invalidateDataAtom";
import Button from "components/common/Button"
import CopyToClipboard from "components/common/CopyToClipboard";
import CheckCircle from "components/common/Icons/CheckCircle";
import DeleteIcon from "components/common/Icons/DeleteIcon";
import useCurrentAuthenticatedState from "hooks/useCurrentAuthenticatedState";
import useToast from "hooks/useToast";
import { useAtom } from "jotai";
import { cachingInvalidation, modalNames } from "utils/cachingConfig";
import { FC, useState } from "react";
import { ApiKey } from "types/dbSchema/apikeys";

export interface TitleProps {
  setNewAPIKeys: React.Dispatch<React.SetStateAction<ApiKey[] | null>>;
}

export const Title: FC<TitleProps> = ({ setNewAPIKeys }) => {
  const [, setCurrentModals] = useAtom(currentModalsAtom);

  const handleNewKey = () => {
    setCurrentModals((currentModals) => [
      ...currentModals,
      {
        id: modalNames.newKey,
        component: NewKeyModalContent,
        props: { setNewAPIKeys },
      },
    ]);
  }

  return (
    <div className="flex flex-row items-center justify-between w-full h-9">
      <p className='w-full text-2xl font-semibold text-text-primary'>API Keys</p>
      <Button
        variant="primary"
        classNames="h-8 min-w-fit text-sm flex items-center justify-center "
        type="button"
        onClick={handleNewKey}
      >
        Generate New Key
      </Button>
    </div>
  )
}

const NewKeyModalContent: FC<TitleProps> = ({ setNewAPIKeys }) => {
  const [, setCurrentModals] = useAtom(currentModalsAtom);
  const [name, setName] = useState('');
  const { addToast } = useToast();
  const [justCreatedKey, setJustCreatedKey] = useState<string | null>(null);
  const [currentState] = useCurrentAuthenticatedState();

  const handleClose = () => {
    setJustCreatedKey(null);
    setCurrentModals((currentModals) => currentModals.filter((modal) => modal.id !== modalNames.newKey));
  }

  const regexValidation = /^[a-zA-Z0-9]{1,20}$/;

  const handleGenerateKey = async () => {
    const resp = await createApiKey({
      name: name,
      // 1 year into the future
      expirationDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      subscriptionPublicId: currentState?.tenant.subscriptions[0].publicId,
    });
    setNewAPIKeys((prev) => prev ? [...prev, resp] : [resp]);
    addToast(SuccessfullyCreatedToast, 'success');
    setName('');
    setJustCreatedKey(resp.plainTextApiKey);
  }

  return (
    <div className="flex flex-col items-center justify-center w-[500px] h-fit p-8 rounded-xl bg-bg-secondary gap-4">
      <p className="text-text-primary text-xl font-semibold text-left w-full">New API Key</p>
      {justCreatedKey &&
        <div className="flex flex-row items-center justify-between max-w-[436px] w-[436px] gap-4 bg-border-complete rounded-lg px-4 py-2">
          <div className="flex flex-col items-start justify-between flex-grow gap-1 overflow-hidden">
            <p className="text-text-complete text-sm font-normal">
              Generated Key
            </p>
            <p className="text-text-primary font-semibold truncate w-full text-sm">
              {justCreatedKey}
            </p>
          </div>
          <div className="w-8 flex-shrink-0">
            <CopyToClipboard text={justCreatedKey} className="text-text-complete" />
          </div>
        </div>
      }
      <div className="flex flex-col w-full gap-1">
        <p className="text-text-secondary text-sm mt-2">API Key Name</p>
        <div className='w-full flex flex-row items-center border border-border-table gap-0.5 p-2 rounded-[4px] text-text-primary bg-bg-primary'>
          <input
            type="text"
            placeholder="Key Name"
            className="outline-none bg-transparent w-full"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        {(name.length > 0 && !regexValidation.test(name)) &&
          <p className="text-text-failed text-sm">Key name must be alphanumeric and less than 20 characters</p>
        }
      </div>
      <p className="text-text-primary text-sm">
        <span>
          After generating the key make sure to
        </span>
        <span className="font-semibold text-failed">
          {` `}copy the key before it becomes hidden.{` `}
        </span>
        <span>
          It will be viewable immediately after being added to the list, but it won’t be visible again once you navigate away.
        </span>
      </p>
      <div className="flex flex-row gap-4 justify-end w-full">
        <Button
          variant="secondary"
          classNames="h-8 min-w-fit text-sm flex items-center justify-center "
          type="button"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          classNames="h-8 min-w-fit text-sm flex items-center justify-center "
          type="button"
          onClick={handleGenerateKey}
          isDisabled={!regexValidation.test(name)}
        >
          Generate Key
        </Button>
      </div>
    </div>
  )
}

export interface DeleteActionProps {
  apiKeyPublicId: string;
}

export const DeleteKeyModalContent: FC<DeleteActionProps> = ({ apiKeyPublicId }) => {
  const [, setCurrentModals] = useAtom(currentModalsAtom);
  const [inputValue, setInputValue] = useState('');
  const { addToast } = useToast();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const handleClose = () => {
    setCurrentModals((currentModals) => currentModals.filter((modal) => modal.id !== modalNames.deleteKey));
  }

  const handleDeleteKey = async () => {
    if (inputValue !== 'DELETE') return;
    await deleteApiKey(apiKeyPublicId);
    setInvalidateData({ [cachingInvalidation.tableName.apiKeys]: true });
    handleClose();
    addToast(SuccessfullyDeletedToast, 'success');
  }

  return (
    <div className="flex flex-col items-center justify-center w-[500px] h-fit p-8 rounded-xl bg-bg-secondary gap-4">
      <div className="flex flex-row items-start justify-between w-full gap-6">
        <div className="rounded-full bg-toast-bg-error p-2">
          <DeleteIcon className="text-toast-icon-error" />
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-1">
          <p className="text-text-primary text-xl font-semibold text-left w-full">Delete API Key</p>
          <div className="flex flex-col w-full gap-1">
            <p className="text-text-secondary text-sm mt-2">
              If you delete the API key, Integrations that use this key won't work. This action cannot be undone.
              <br />
              <br />
              To delete, type DELETE below and confirm the action.</p>
            <div className='w-full flex flex-row items-center border border-border-table gap-0.5 p-2 rounded-[4px] text-text-primary bg-bg-primary'>
              <input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value.toUpperCase())}
                type="text"
                placeholder="DELETE"
                className="outline-none bg-transparent w-full"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-4 justify-end w-full">
        <Button
          variant="secondary"
          classNames="min-w-fit text-sm flex items-center justify-center"
          type="button"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="error"
          classNames="min-w-fit text-sm flex items-center justify-center"
          type="button"
          isDisabled={inputValue !== 'DELETE'}
          onClick={handleDeleteKey}
        >
          Delete API Key
        </Button>
      </div>
    </div>
  )
}

const SuccessfullyDeletedToast = () => {
  return (
    <div className="flex flex-row items-center justify-between w-full gap-4">
      <div>
        <CheckCircle className="text-toast-icon-success" />
      </div>
      <p className="text-sm">
        Successfully deleted API Key
      </p>
    </div>
  )
}

const SuccessfullyCreatedToast = () => {
  return (
    <div className="flex flex-row items-center justify-between w-full gap-4">
      <div>
        <CheckCircle className="text-toast-icon-success" />
      </div>
      <p className="text-sm">
        Successfully created a new API Key
      </p>
    </div>
  )
}