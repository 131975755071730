import { FC, useMemo, useState } from "react";
import Table from "components/common/Table";
import { InvoiceOverview } from "types/dbSchema/invoices";
import { columns } from "./Columns";
import { TableHeaderProps } from "../../types";
import { cn } from "utils";

export interface LineItemsTabProps {
  containerHeight: number;
  currentInvoice: InvoiceOverview;
}

const LineItemsTab: FC<LineItemsTabProps> = ({ containerHeight, currentInvoice }) => {
  const [showErrors, setShowErrors] = useState(false);

  // Combine line items with their respective validation
  const lineItemsData = useMemo(
    () =>
      currentInvoice.invoice.items.map((item, index) => ({
        ...item,
        validation: currentInvoice.validation.lineItems[index],
        isInvoiceValid: currentInvoice.validation.valid,
      })),
    [currentInvoice]
  );

  // Extract those with errors
  const errorLineItemsData = useMemo(
    () => lineItemsData.filter((item) => item.validation.integrity),
    [lineItemsData]
  );

  // Counts for line items and line items with errors
  const lineItemsAmount = lineItemsData.length;
  const lineItemsWithErrors = errorLineItemsData.length;

  const displayedData = showErrors ? errorLineItemsData : lineItemsData;

  const calculatedTotal = useMemo(() => {
    return currentInvoice.invoice.items.reduce(
      (acc, item) => acc + item.amount, 0
    )
  }, [currentInvoice]);

  const calculatedGST = useMemo(() => {
    return currentInvoice.invoice.items.reduce(
      (acc, item) => acc + item.tax, 0
    )
  }, [currentInvoice]);

  return (
    <div
      className="mt-6"
      style={{ height: `calc(100vh - ${containerHeight + 224}px)` }}
    >
      <Table
        columns={columns}
        currentPage={0}
        data={displayedData}
        heightOffset={0}
        isError={false}
        isFetching={false}
        rowsPerPage={lineItemsAmount}
        setCurrentPage={() => { }}
        setRowsPerPage={() => { }}
        total={lineItemsAmount}
        pageNumbersOptions={[lineItemsAmount]}
        tableHeader={
          <TableHeader
            lineItemsAmount={lineItemsAmount}
            lineItemsWithErrors={lineItemsWithErrors}
            isShowErrors={showErrors}
            setIsShowErrors={setShowErrors}
          />
        }
        tableFooter={
          <TableFooter
            calculatedTotalIsValid={currentInvoice.validation.total}
            calculatedTotal={calculatedTotal}
            calculatedGST={calculatedGST}
          />
        }
      />
    </div>
  );
};

export interface TableFooterProps {
  calculatedTotal: number;
  calculatedGST: number;
  calculatedTotalIsValid: boolean;
}

const TableFooter: FC<TableFooterProps> = ({ calculatedTotal, calculatedGST, calculatedTotalIsValid }) => {
  return (
    <div className="w-full flex justify-end items-center h-[42px] bg-nav-bg border-t px-2 border-border-table gap-2">
      <p className={cn(
        "text-sm font-semibold",
        calculatedTotalIsValid ? "text-text-primary" : "text-text-incomplete"
      )}>
        Calculated Total: ${calculatedTotal}
      </p>
      <p className={cn(
        "text-sm font-semibold",
        calculatedTotalIsValid ? "text-text-primary" : "text-text-incomplete"
      )}>
        GST: ${calculatedGST}
      </p>
    </div>
  )
};

const TableHeader: FC<TableHeaderProps> = ({
  lineItemsAmount,
  lineItemsWithErrors,
  isShowErrors,
  setIsShowErrors,
}) => {
  return (
    <div className="flex justify-between items-center h-[42px] bg-nav-bg py-3 px-4 border-b border-border-table">
      <div className="flex items-center gap-6">
        <p className="text-base font-semibold text-text-secondary">Line Items ({lineItemsAmount})</p>
        <div className="w-[1px] h-6 bg-border-main" />
        <div className="flex gap-2">
          <input
            type="checkbox"
            id="showErrorsCheckbox"
            checked={isShowErrors}
            onChange={() => setIsShowErrors(!isShowErrors)}
            className={cn(
              "relative peer shrink-0 appearance-none w-4 h-4 border border-border-main cursor-pointer",
              "rounded-sm bg-nav-bg m-auto checked:bg-text-tertiary checked:border-0"
            )}
          />
          <label htmlFor="showErrorsCheckbox">Show line items errors ({lineItemsWithErrors})</label>
          <svg
            className="absolute w-4 h-4 mt-1 hidden peer-checked:block cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            onClick={() => setIsShowErrors(!isShowErrors)}
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default LineItemsTab;