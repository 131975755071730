import AddIcon from "components/common/Icons/AddIcon"
import CloseIcon from "components/common/Icons/CloseIcon"
import MinusIcon from "components/common/Icons/MinusIcon"
import PureVuImageViewIcon from "components/common/Icons/PureVuImageViewIcon"
import ImageWithZoom from "components/common/ImageWithZoom"
import Toggle from "components/common/Toggle"
import { FC, useState } from "react"
import { cn } from "utils"

export interface ImageViewProps {
  imgSrc: string | null
}

const ImageView: FC<ImageViewProps> = ({ imgSrc }) => {
  const [scale, setScale] = useState(1);;

  // Handlers for zooming
  const handleZoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom at 3x
  const handleZoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom at 0.5x
  const handleResetZoom = () => setScale(1);

  return (
    <div className='relative w-full h-full text-center gap-4 flex flex-col justify-between'>
      <div className={cn(
        "bg-bg-secondary transition-[background-color] ease-in-out duration-300 border border-border-secondary",
        "w-full rounded-[4px] flex flex-col items-center justify-center flex-1 max-h-[calc(100%-52px)]"
      )}>
        {!imgSrc ? (
          <>
            <PureVuImageViewIcon className="h-48 w-48 mb-[-20px]" />
            <p className='text-text-primary text-base'>
              Preview image will be displayed here
            </p>
          </>
        ) : (
          <ImageWithZoom
            imgSrcArray={[imgSrc]}
            scale={scale}
            setScale={setScale}
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
            handleResetZoom={handleResetZoom}
          />
        )}
      </div>
      <div className='h-9 flex flex-row text-text-primary items-center justify-end gap-4'>
        <Toggle label='Show selection mask' isChecked={false} isDisabled={imgSrc === null} />
        {scale !== 1 && (
          <div onClick={handleResetZoom}>
            <CloseIcon
              className={cn(
                'h-9 w-9 text-text-secondary border border-border-main rounded-[4px] p-1.5 cursor-pointer',
                !imgSrc && 'opacity-50 cursor-default'
              )}
            />
          </div>
        )}
        <div onClick={handleZoomIn}>
          <AddIcon
            className={cn(
              'h-9 w-9 text-text-secondary border border-border-main rounded-[4px] p-1.5 cursor-pointer',
              !imgSrc && 'opacity-50 cursor-default'
            )}
          />
        </div>
        <div onClick={handleZoomOut}>
          <MinusIcon
            className={cn(
              'h-9 w-9 text-text-secondary border border-border-main rounded-[4px] p-1.5 cursor-pointer',
              !imgSrc && 'opacity-50 cursor-default'
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default ImageView