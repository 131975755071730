import Result from "components/common/Result";
import { Column } from "components/common/Table/types";
import { getInvoiceResult } from "utils";
import { InvoiceOverview } from "types/dbSchema/invoices";

export const columns: Column[] = [
  {
    key: 'invoice.providerName',
    name: 'Service Provider',
    initialWidth: 300,
    minWidth: 300,
    maxWidth: 500,
  },
  {
    key: 'invoice.participantName',
    name: 'Participant',
    initialWidth: 300,
    minWidth: 300,
    maxWidth: 500,
  },
  {
    key: 'invoice.invoiceNumber',
    name: 'Invoice Number',
    initialWidth: 200,
    minWidth: 200,
    maxWidth: 300,
  },
  {
    key: 'result',
    name: 'Result',
    initialWidth: 200,
    minWidth: 200,
    maxWidth: 300,
    fillSpace: true,
    renderComponent: (props: { row: InvoiceOverview }) => (
      <Result result={getInvoiceResult(props.row)} />
    )
  },
  {
    key: 'uploadDate',
    name: 'Date Submitted',
    initialWidth: 200,
    minWidth: 200,
    maxWidth: 200,
    renderComponent: (props: { row: InvoiceOverview }) => {
      const date = new Date(`${props.row.uploadDate}Z`);

      const formattedDate = date.toLocaleDateString("en-AU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      // For HH:MM (24-hour format with leading zero for hours)
      const formattedTime = date.toLocaleTimeString("en-AU", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true, // ensures 24-hour clock
      });

      return (
        <p className=" text-sm text-text-primary font-normal">
          {`${formattedDate} ${formattedTime}`}
        </p>
      )
    }
  }
]