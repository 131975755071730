// Toast.tsx
import { useEffect } from 'react';
import { cn } from "utils";
import CloseIcon from '../Icons/CloseIcon';

interface ToastProps {
  id: string;
  component: React.ComponentType<any>
  type: 'success' | 'error';
  removeToast: (id: string) => void;
}

const Toast: React.FC<ToastProps> = ({ id, component: Component, type, removeToast }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(id);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [id, removeToast]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={cn(
        'p-4 rounded-md shadow-lg flex flex-row items-center gap-4',
        type === 'success' ? 'bg-toast-bg-success text-toast-text-success' : 'bg-toast-bg-error text-toast-text-error',
      )}
      role="alert"
      aria-live="assertive"
    >
      <Component />
      <button className="cursor-pointer" onClick={() => removeToast(id)}>
        <CloseIcon className="text-toast-icon-success" />
      </button>
    </div>
  );
};

export default Toast;