import { format } from "date-fns"
import CalendarDateRangeIcon from "../Icons/CalendarDateRangeIcon"
import { DateRange, SelectRangeEventHandler } from "react-day-picker"
import { cn } from "utils"
import { Button } from "components/ui/button"
import { Calendar } from "components/ui/calendar"
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover"
import { FC, useState } from "react"
import ChevronDownIcon from "../Icons/ChevronDownIcon"

export interface DatePickerWithRangeProps {
  className?: string
  date: DateRange | undefined
  setDate: SelectRangeEventHandler
  placeholderText?: string
}

const DatePickerWithRange: FC<DatePickerWithRangeProps> = ({ className, date, setDate, placeholderText = 'Pick a date' }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "lg:w-[300px] justify-between text-left font-normal bg-bg-secondary text-text-primary border-border-table",
            )}
          >
            <div className="flex items-center justify-start gap-2">
              <CalendarDateRangeIcon />
              <span className="lg:block hidden">
                {date?.from ? (
                  date.to ? (
                    <>
                      {format(date.from, "LLL dd, y")} -{" "}
                      {format(date.to, "LLL dd, y")}
                    </>
                  ) : (
                    format(date.from, "LLL dd, y")
                  )
                ) : (
                  <span>{placeholderText}</span>
                )}
              </span>
            </div>
            <ChevronDownIcon
              className={cn(
                "w-4 h-4",
                "transform transition-transform duration-200",
                open ? "rotate-180" : "rotate-0"
              )}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 bg-bg-primary text-text-primary border-border-table" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default DatePickerWithRange