import ProductsOverview from 'components/common/ProductsOverview';
import ResourcesOverview from 'components/common/ResourcesOverview';
import WelcomeMessage from 'components/common/WelcomeMessage';
import { FC } from 'react'

const HomePage: FC = () => {
  return (
    <div className='flex flex-col w-full h-full items-center justify-start max-w-[1200px] p-8 gap-12'>
      <WelcomeMessage />
      <ProductsOverview />
      <ResourcesOverview />
    </div>
  )
}

export default HomePage