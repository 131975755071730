import { FC, useMemo, useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import useInvalidateData from 'hooks/useInvalidateData'
import { cachingInvalidation } from 'utils/cachingConfig'
import FullscreenLoadingIndicator from 'components/common/FullscreenLoadingIndicator'
import Title from './Title'
import Table from 'components/common/Table'
import { columns } from './Columns'
import { useNavigate } from 'react-router-dom'
import { getInvoices } from 'api/invoice'
import { DateRange } from 'react-day-picker'
import { formatDateRange } from 'utils'


const InvoicesTable: FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [date, setDate] = useState<DateRange | undefined>(undefined)
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])
  const [search, setSearch] = useState<string>('')
  useInvalidateData(cachingInvalidation.tableName.invoices);
  const navigate = useNavigate();

  const { formattedFromDate, formattedToDate } = useMemo(() => formatDateRange(date), [date]);

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: [
      cachingInvalidation.tableName.invoices,
      currentPage,
      rowsPerPage,
      date?.from,
      date?.to,
      selectedFilters,
      search
    ],
    queryFn: () => getInvoices(
      currentPage,
      rowsPerPage,
      'UploadDate',
      'desc',
      formattedFromDate,
      formattedToDate,
      [], // Processing States - not currently used
      selectedFilters as any,
      search
    ),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.time.invoices,
  });

  const handleRowClick = (row: any) => navigate(`/tru-scan/invoices/${row.publicId}`);

  if (isPending) return <FullscreenLoadingIndicator />

  return (
    <div className="flex flex-col w-full h-full gap-4 max-w-[1600px]">
      <Title
        date={date}
        selectedFilters={selectedFilters}
        search={search}
        setDate={setDate}
        setSelectedFilters={setSelectedFilters}
        setSearch={setSearch}
      />
      <Table
        columns={columns}
        data={data?.data || []}
        heightOffset={142}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        total={data?.totalCount ?? 0}
        isError={isError}
        isFetching={isFetching}
        onRowClick={handleRowClick}
        pageNumbersOptions={[10, 20, 50]}
      />
    </div>
  )
}

export default InvoicesTable