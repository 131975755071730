import { useState, useEffect, useRef, FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavigationTab } from 'types';
import { cn } from 'utils';

export interface NavTabsProps {
  navigationTabs: NavigationTab[]
}

const NavTabs: FC<NavTabsProps> = ({ navigationTabs }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const [indicatorStyles, setIndicatorStyles] = useState<{ left: number; width: number } | null>(null);
  const tabsRef = useRef<Array<HTMLButtonElement | null>>([]);

  useEffect(() => {
    const currentTab = navigationTabs.find((tab) => location.pathname.includes(tab.path!)) || null;
    if (currentTab) {
      setSelectedTab(currentTab.label);
    } else {
      setSelectedTab(null);
    }
  }, [location.pathname, navigationTabs]);

  useEffect(() => {
    if (selectedTab === null) {
      setIndicatorStyles(null);
      return;
    }

    const currentTab = tabsRef.current.find(
      (tab) => tab?.dataset.label === selectedTab
    );

    if (currentTab) {
      setIndicatorStyles({
        left: currentTab.offsetLeft,
        width: currentTab.offsetWidth,
      });
    } else {
      setIndicatorStyles(null);
    }
  }, [selectedTab, navigationTabs]);

  const handleTabChange = (label: string, path?: string) => {
    setSelectedTab(label);
    if (path) navigate(path);

  };

  return (
    <div className="relative h-full">
      <div className="flex items-center justify-center h-full">
        {navigationTabs.map((tab, index) => {
          const isSelected = selectedTab === tab.label;
          const Icon = tab.icon;
          return (
            <button
              key={tab.label}
              data-label={tab.label}
              ref={(el) => (tabsRef.current[index] = el)}
              onClick={() => handleTabChange(tab.label, tab.path)}
              className={cn(
                'relative flex flex-row items-center px-4 py-2 gap-2 transition-colors duration-200',
                isSelected ? 'text-nav-selected font-semibold' : 'text-white font-medium'
              )}
            >
              <Icon className={cn(
                'h-6 w-6',
                isSelected ? 'text-nav-selected' : 'text-nav-icon'
              )} />
              {tab.label}
            </button>
          );
        })}
      </div>
      {indicatorStyles && (
        <span
          className="absolute bottom-0 h-1 bg-nav-selected transition-all duration-300"
          style={{
            left: indicatorStyles.left,
            width: indicatorStyles.width,
          }}
        />
      )}
    </div>
  );
};

export default NavTabs;