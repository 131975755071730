import { FC } from 'react';
import { IconProps } from './types';

const PureVuErrorIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 160 120"
      className={className}
    >
      {/* Path with cls-7 styles */}
      <path
        d="M145.42,80.9c1.32,0,2.4-1.07,2.4-2.4s-1.07-2.4-2.4-2.4-2.4,1.07-2.4,2.4,1.07,2.4,2.4,2.4Z"
        stroke="rgba(239, 68, 68, 0.4)"
        strokeWidth="1.6"
        fill="none"
      />

      {/* Path with cls-3 styles */}
      <path
        d="M129.89,76.1c1.33,0,2.4-1.07,2.4-2.4s-1.07-2.4-2.4-2.4-2.4,1.07-2.4,2.4,1.07,2.4,2.4,2.4Z"
        fill="rgba(239, 68, 68, 0.4)"
        fillRule="evenodd"
      />

      {/* Path with cls-1 styles */}
      <path
        d="M139.08,85.23l-6.69,6.69,6.69-6.69ZM132.29,85.23l6.69,6.69-6.69-6.69Z"
        fill="rgba(239, 68, 68, 0.4)"
        fillRule="evenodd"
      />

      {/* Path with cls-6 styles */}
      <path
        d="M132.29,85.23l6.69,6.69M139.08,85.23l-6.69,6.69,6.69-6.69Z"
        stroke="rgba(239, 68, 68, 0.4)"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinecap="round"
        fill="none"
      />

      {/* Path with cls-1 styles */}
      <path
        d="M29.42,20.3l-4.8,4.8,4.8-4.8ZM24.62,20.3l4.8,4.8-4.8-4.8Z"
        fill="rgba(239, 68, 68, 0.4)"
        fillRule="evenodd"
      />

      {/* Path with cls-6 styles */}
      <path
        d="M24.62,20.3l4.8,4.8M29.42,20.3l-4.8,4.8,4.8-4.8Z"
        stroke="rgba(239, 68, 68, 0.4)"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinecap="round"
        fill="none"
      />

      {/* Path with cls-3 styles */}
      <path
        d="M23.45,39.43c1.33,0,2.4-1.07,2.4-2.4s-1.07-2.4-2.4-2.4-2.4,1.07-2.4,2.4,1.07,2.4,2.4,2.4Z"
        fill="rgba(239, 68, 68, 0.4)"
        fillRule="evenodd"
      />

      {/* Group containing multiple paths */}
      <g>
        {/* Path with cls-5 styles */}
        <path
          d="M48.4,25.8h63.2c1.21,0,2.2.99,2.2,2.2v64c0,1.22-.99,2.2-2.2,2.2h-63.2c-1.22,0-2.2-.98-2.2-2.2V28c0-1.22.98-2.2,2.2-2.2Z"
          stroke="#ef4444"
          strokeWidth="2"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
        />

        {/* Another Path with cls-5 styles */}
        <path
          d="M54.8,33.02h50.4c.77,0,1.4.63,1.4,1.4v42.4c0,.77-.63,1.4-1.4,1.4h-50.4c-.77,0-1.4-.63-1.4-1.4v-42.4c0-.77.63-1.4,1.4-1.4Z"
          stroke="#ef4444"
          strokeWidth="2"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
        />

        {/* Nested Group containing cls-4 paths */}
        <g>
          {/* Path with cls-4 styles */}
          <path
            d="M87.47,45.4l6.69,6.69M94.26,45.4l-6.69,6.69,6.69-6.69Z"
            stroke="#ef4444"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeLinecap="round"
            fill="none"
          />

          {/* Another Path with cls-4 styles */}
          <path
            d="M65.74,45.4l6.69,6.69M72.53,45.4l-6.69,6.69,6.69-6.69Z"
            stroke="#ef4444"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeLinecap="round"
            fill="none"
          />
        </g>

        {/* Path with cls-8 styles */}
        <path
          d="M75.25,66.58c1.25-1.25,2.93-1.88,4.7-1.88s3.55.63,4.81,1.88"
          stroke="#ef4444"
          strokeWidth="2.5"
          strokeLinecap="round"
          fill="none"
        />

        {/* Path with cls-2 styles */}
        <path
          d="M108.55,29.1h-57.1c-1.1,0-2,.9-2,2v57.8c0,1.1.9,2,2,2h57.1c1.1,0,2-.9,2-2V31.1c0-1.1-.9-2-2-2ZM106.6,76.82c0,.77-.63,1.4-1.4,1.4h-50.4c-.77,0-1.4-.63-1.4-1.4v-42.4c0-.77.63-1.4,1.4-1.4h50.4c.77,0,1.4.63,1.4,1.4v42.4Z"
          fill="rgba(239, 68, 68, 0.08)"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default PureVuErrorIcon;