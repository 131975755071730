import axios from 'axios';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import publicClientApplication from 'utils/msalInstance';

// Create an Axios instance with default configurations
// If the headers are different than the default, you can override them in the API call by passing them in as you would normally
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'text/plain',
  },
});

// Add an interceptor to attach the access token to each request
// We need to do this because axios calls are synchronous and we need to wait for the access token to be retrieved
axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = await getAccessToken();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// This function handles acquiring the token silently, or through a redirect if necessary
export async function getAccessToken() {
  // Get the account object for the user
  const accounts = publicClientApplication.getAllAccounts();
  if (accounts.length === 0) throw new Error('No accounts detected.');

  const scopes = [
    'openid',
    process.env.REACT_APP_API_SCOPE as string,
  ];

  const accessTokenRequest = {
    scopes: scopes,
    account: accounts[0],
  };

  try {
    const accessTokenResponse = await publicClientApplication.acquireTokenSilent(accessTokenRequest);
    return accessTokenResponse.accessToken;
  } catch (error) {
    // If silent token acquisition fails, fallback to an interactive request
    if (error instanceof InteractionRequiredAuthError) {
      try {
        publicClientApplication.acquireTokenRedirect(accessTokenRequest);
      } catch (interactiveError) {
        console.error(interactiveError);
        throw interactiveError;
      }
    } else {
      console.error(error);
      throw error;
    }
  }
}

export default axiosInstance;