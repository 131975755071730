import { createElement } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { catchAllRoute, publicRoutes, routes } from 'utils/navigation';
import LayoutProvider from '../LayoutProvider';
import ProtectedRoute from 'components/common/ProtectedRoute';
import { RouteConfig } from 'types';

const RoutesProvider: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Outlet />} />
      {routes.map((route, index) => {
        if (publicRoutes.includes(route.path)) {
          return renderPublicRoute(route, index);
        } else if (route.useLayout) {
          return renderPrivateRouteWithLayout(route, index);
        } else {
          return renderPrivateRoute(route, index);
        }
      })}
      <Route path="*" element={<Navigate to={catchAllRoute} />} />
    </Routes>
  );
};

// Private routes with layout
const renderPrivateRouteWithLayout = (page: RouteConfig, index: number) => {
  return (
    <Route
      path={page.path}
      index={page.isIndex ?? false}
      key={index}
      element={
        <ProtectedRoute>
          <LayoutProvider>
            {createElement(page.component)}
          </LayoutProvider>
        </ProtectedRoute>
      }
    />
  )
};

// Private routes
const renderPrivateRoute = (page: RouteConfig, index: number) => {
  return (
    <Route
      path={page.path}
      index={page.isIndex ?? false}
      key={index}
      element={
        <ProtectedRoute>
          {createElement(page.component)}
        </ProtectedRoute>
      }
    />
  )
};

// Public routes
const renderPublicRoute = (page: RouteConfig, index: number) => {
  return (
    <Route
      path={page.path}
      index={page.isIndex ?? false}
      key={index}
      element={createElement(page.component)}
    />
  )
};

export default RoutesProvider;