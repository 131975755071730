import Result from 'components/common/Result';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import Tabs from 'components/common/Tabs';
import InvoiceDetailsTab from './InvoiceDetailsTab';
import LineItemsTab from './LineItemsTab';
import InvoiceValidationChecks from 'components/common/InvoiceMarker';
import { getInvoiceResult } from 'utils';
import { ViewInvoiceProps } from '../types';

const DetailsSection: FC<ViewInvoiceProps> = ({ currentInvoice }) => {
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.clientHeight);
      }
    };

    // Initial height set
    updateHeight();

    // Using ResizeObserver to observe changes in the container’s height
    const resizeObserver = new ResizeObserver(updateHeight);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Cleanup the observer on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const invoiceResult = useMemo(() => {
    return getInvoiceResult(currentInvoice);
  }, [currentInvoice]);

  return (
    <div className="flex flex-col items-center justify-start p-8 gap-8 h-full" >
      <div className="flex flex-col items-center justify-center gap-6 w-full" ref={containerRef}>
        <div className="flex flex-col items-center justify-center gap-2 w-full">
          <div className="flex flex-row items-center justify-start w-full gap-4">
            <p className="text-xl text-text-primary font-semibold">{currentInvoice?.invoice.invoiceNumber}</p>
            <Result result={invoiceResult} />
          </div>
          <p className="text-text-secondary text-sm text-left w-full">
            {currentInvoice?.storedFile.fileName ?? 'No invoice image available'}
          </p>
        </div>
        {currentInvoice && <InvoiceValidationChecks currentInvoice={currentInvoice} />}
      </div>
      {!currentInvoice || currentInvoice.processingState === "Error" ? (
        <Tabs
          tabs={[
            {
              label: 'Invoice Details',
              content: () => <InvoiceDetailsTab containerHeight={containerHeight} currentInvoice={currentInvoice} />,
            },
          ]}
        />
      ) : (
        <Tabs
          tabs={[
            {
              label: 'Invoice Details',
              content: () => <InvoiceDetailsTab containerHeight={containerHeight} currentInvoice={currentInvoice} />,
            },
            {
              label: 'Line Items',
              content: () => <LineItemsTab containerHeight={containerHeight} currentInvoice={currentInvoice} />,
            },
          ]}
        />
      )}
    </div>
  );
};

export default DetailsSection;