import DeleteIcon from "components/common/Icons/DeleteIcon"
import { FC } from "react"
import { DeleteActionProps } from "../types";
import CopyToClipboard from "components/common/CopyToClipboard";
import { useAtom } from "jotai";
import currentModalsAtom from "atoms/currentModalsAtom";
import { DeleteKeyModalContent } from "../Title";
import { modalNames } from "utils/cachingConfig";
import { ApiKey } from "types/dbSchema/apikeys";
import { Column } from "components/common/Table/types";

export const columns: Column[] = [
  {
    key: 'name',
    name: 'Name',
    initialWidth: 250,
    minWidth: 250,
    maxWidth: 250,
  },
  {
    key: 'api_key',
    name: 'API Key',
    initialWidth: 400,
    minWidth: 500,
    maxWidth: 950,
    fillSpace: true,
    renderComponent: (props: any) => (
      <CopyApiKey {...props} />
    ),
  },
  {
    key: 'creationDate',
    name: 'Created',
    initialWidth: 200,
    minWidth: 200,
    maxWidth: 200,
    renderComponent: (props: { row: ApiKey }) => (
      <p className=" text-sm text-text-primary font-normal">
        {new Date(props.row.creationDate).toLocaleDateString('en-AU')}
      </p>
    )
  },
  {
    key: 'delete',
    name: 'Action',
    initialWidth: 100,
    minWidth: 100,
    maxWidth: 100,
    renderComponent: (props: any) => (
      <DeleteAction {...props} />
    ),
  }
]

const CopyApiKey = ({ row }: any) => {
  const canCopy = row.plainTextApiKey && row.plainTextApiKey.length > 7;

  return (
    <div className="flex items-center w-full justify-start gap-2">
      <div className="truncate">{row.plainTextApiKey}</div>
      {canCopy && <CopyToClipboard text={row.plainTextApiKey} />}
    </div>
  );
};

const DeleteAction: FC<DeleteActionProps> = ({ row }) => {
  const [, setCurrentModals] = useAtom(currentModalsAtom);

  const handleDelete = () => {
    setCurrentModals((currentModals) => [
      ...currentModals,
      {
        id: modalNames.deleteKey,
        component: DeleteKeyModalContent,
        props: { apiKeyPublicId: row.publicId },
      },
    ]);
  }

  return (
    <div className="flex justify-center cursor-pointer text-icon-main" onClick={handleDelete}>
      <DeleteIcon className="h-6 w-6" />
    </div>
  );
};