export const getChartOptions = (series: any) => {
  if (!series.length || !series[0]?.data) return {};
  const daysInMonth = series[0]?.data?.map((_: any, idx: number) => idx + 1) ?? [];

  return {
    chart: {
      id: 'basic-bar',
      stacked: true,
    },
    states: {
      hover: {
        filter: {
          type: 'none', // Disable hover effect
        },
      },
    },
    tooltip: {
      followCursor: true,
      enabled: true,
      custom: function ({ dataPointIndex, w }: any) {
        const date = new Date(series[0].dates[dataPointIndex]).toLocaleDateString('en-AU');
        const seriesData = w.config.series;
        let total = 0;

        // Loop over all series to build the tooltip content
        let seriesContent = '';
        seriesData.forEach((s: any, i: any) => {
          const value = s.data[dataPointIndex];
          const seriesName = s.name;
          const color = w.globals.colors[i];
          total += value;

          seriesContent += `
        <span class="text-sm flex gap-1 items-center">
          <div class="w-3 h-3 rounded-[4px]" style="background-color: ${color}"></div>
          <p class="font-medium text-text-secondary">${seriesName}:</p>
          <p class="font-semibold">${value}</p>
        </span>
      `;
        });

        return `
      <div class="text-text-primary border border-border-table bg-bg-primary min-w-[130px]">
        <div class="p-2 bg-bg-secondary">
          <p class="text-sm font-semibold">
            ${date}
          </p>
        </div>
        <div class="flex flex-col p-2 gap-2">
          <div>
            ${seriesContent}
          </div>
          <div class="pt-2 border-t border-border-table text-sm flex gap-1 items-center">
              <p class="font-medium text-text-secondary">Total</p>
              <p class="font-semibold">${total}</p>
          </div>
        </div>
      </div>
    `;
      },
    },
    xaxis: {
      categories: daysInMonth,
      labels: {
        style: {
          colors: 'var(--text-primary)',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: 'var(--text-primary)',
        },
      },
    },
    legend: {
      labels: {
        colors: 'var(--text-primary)',
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '60%',
        dataLabels: {
          position: 'top'
        }
      },
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
  }
};