import { formatFileSize, getFileIcon, getUsage } from 'utils';
import Button from 'components/common/Button';
import CloseIcon from 'components/common/Icons/CloseIcon';
import ErrorIcon from 'components/common/Icons/ErrorIcon';
import { FC, useMemo } from 'react';
import { uploadInvoiceBatch } from 'api/invoice';
import { useAtom } from 'jotai';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { cachingInvalidation } from 'utils/cachingConfig';
import useCurrentAuthenticatedState from 'hooks/useCurrentAuthenticatedState';

export interface FileDropdownProps {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setFileDropdownIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  errors: string[];
  fileSizeLimit: number;
}

const FileDropdown: FC<FileDropdownProps> = ({ files, setFiles, setFileDropdownIsOpen, errors, fileSizeLimit }) => {
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const [currentState, , fetchCurrentState] = useCurrentAuthenticatedState();
  const sizeErrorCount = errors.filter((error: string) => error === 'size').length;
  const typeErrorCount = errors.filter((error: string) => error === 'type').length;

  // Get current usage for Document Intelligence
  const currentUsage = useMemo(() => getUsage(currentState, "DocumentIntelligence"), [currentState]);
  // Check if the submit button should be disabled
  const isSubmitDisabled = files.length === 0 || currentUsage.remaining <= 0;

  const handleRemoveByIndex = (index: number) => {
    setFiles((prevFiles: any) => prevFiles.filter((_: any, i: number) => i !== index));
    if (files.length === 1) setFileDropdownIsOpen(false);
  }

  const handleRemoveAll = () => {
    // Close dropdown and reset files
    setFiles([]);
    setFileDropdownIsOpen(false);
  }

  const handleUpload = async () => {
    try {
      // Create FormData object and append each file to it
      const formData = new FormData();
      files.forEach((file: any) => formData.append('files', file));
      // Make API call to upload files
      await uploadInvoiceBatch(formData);
      // Invalidate cache for invoices
      setInvalidateData({ [cachingInvalidation.tableName.invoices]: true });
      // Refetch current state to update the UI
      fetchCurrentState();
      // Close dropdown and reset files
      setFiles([]);
      setFileDropdownIsOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className='absolute top-14 right-0 z-50 bg-bg-primary w-[500px] h-[500] border border-border-table rounded-lg shadow-lg'>
      <div className='flex flex-col p-4 gap-2'>
        <p className='text-text-secondary text-xs font-semibold uppercase'>Files pending upload</p>
        {files.length > 0 && (
          <ul className='flex flex-col gap-2 w-full'>
            {Array.from(files).map((file: any, index) => (
              <li key={index} className='py-3 px-4 rounded-lg bg-bg-secondary flex items-center justify-between'>
                <div className='flex flex-row gap-2 items-center'>
                  {getFileIcon(file.type)}
                  <p className='overflow-hidden whitespace-nowrap text-ellipsis w-[200px]'>
                    {file.name}
                  </p>
                </div>
                <CloseIcon className='text-text-secondary size-4' onClick={() => handleRemoveByIndex(index)} />
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className='flex flex-col justify-center items-end gap-4 p-4 border-t border-border-table'>
        {sizeErrorCount > 0 && (
          <div className='bg-bg-failed w-full items-center py-2 px-4 rounded-lg flex flex-row gap-2'>
            <ErrorIcon className='text-failed size-5' />
            <p className='text-text-primary text-sm'>
              {sizeErrorCount} file{sizeErrorCount > 1 ? 's' : ''} could not be added. Files exceed {formatFileSize(fileSizeLimit)} limit.
            </p>
          </div>
        )}
        {typeErrorCount > 0 && (
          <div className='bg-bg-failed w-full items-center py-2 px-4 rounded-lg flex flex-row gap-2'>
            <ErrorIcon className='text-failed size-5' />
            <p className='text-text-primary text-sm'>
              {typeErrorCount} file{typeErrorCount > 1 ? 's' : ''} could not be added. Unsupported file type.
            </p>
          </div>
        )}
        <div className='flex flex-row justify-end items-center gap-4'>
          <Button
            variant='secondary'
            type='reset'
            classNames='text-sm'
            onClick={handleRemoveAll}
          >
            Clear
          </Button>
          <Button
            variant='primary'
            type='submit'
            classNames='text-sm'
            onClick={handleUpload}
            isDisabled={isSubmitDisabled}
          >
            Upload Files
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FileDropdown;