import useCurrentAuthenticatedState from "hooks/useCurrentAuthenticatedState";
import { FC } from "react";

const WelcomeMessage: FC = () => {
  const [currentState] = useCurrentAuthenticatedState();
  return (
    <div className='w-full'>
      <p className='text-lg text-text-secondary'>
        {new Date().toLocaleDateString('en-GB', { weekday: 'long', day: 'numeric', month: 'long' })}
      </p>
      <p className='text-3xl text-text-primary font-semibold'>
        Welcome back, {currentState?.authenticatedUser.fullName}
      </p>
    </div>
  )
}

export default WelcomeMessage