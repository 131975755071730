import { FC, useRef, useEffect, useState } from 'react';
import { Column, TableProps } from './types';
import PaginationFooter from 'components/common/Table/PaginationFooter';
import './styles.css';
import { cn, getNestedValue } from 'utils';
import { LoadingIndicator } from '../FullscreenLoadingIndicator';
import useColumnWidths from 'hooks/useColumnWidth';
import { cachingInvalidation } from 'utils/cachingConfig';

const Table: FC<TableProps> = ({
  columns,
  data,
  heightOffset,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  total,
  isError,
  isFetching,
  onRowClick,
  pageNumbersOptions,
  footerVariant = 'full',
  tableHeader,
  tableFooter,
}) => {
  const columnHeaderHeight = 48;
  const headerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  // References to measure the heights of tableHeader and tableFooter (if provided)
  const tableHeaderRef = useRef<HTMLDivElement>(null);
  const tableFooterRef = useRef<HTMLDivElement>(null);

  const [tableHeaderHeight, setTableHeaderHeight] = useState<number>(0);
  const [tableFooterHeight, setTableFooterHeight] = useState<number>(0);
  const [scrollbarWidth, setScrollbarWidth] = useState<number | null>(null);

  const defaultWidths: number[] = columns.map((col) => {
    return col.initialWidth;
  });

  const { columnWidths, updateColumnWidth } = useColumnWidths(cachingInvalidation.tableName.invoices, defaultWidths);

  // Measure the heights of the tableHeader and tableFooter after render
  useEffect(() => {
    const headerHeight = tableHeaderRef.current?.offsetHeight ?? 0;
    const footerHeight = tableFooterRef.current?.offsetHeight ?? 0;
    setTableHeaderHeight(headerHeight);
    setTableFooterHeight(footerHeight);
  }, [tableHeader, tableFooter]);

  // Syncs the horizontal scroll of the header and body of the table
  useEffect(() => {
    const handleBodyScroll = () => {
      if (headerRef.current && bodyRef.current) {
        headerRef.current.scrollLeft = bodyRef.current.scrollLeft;
      }
    };
    const bodyElement = bodyRef.current;
    if (bodyElement) {
      bodyElement.addEventListener('scroll', handleBodyScroll);
    }
    return () => {
      if (bodyElement) bodyElement.removeEventListener('scroll', handleBodyScroll);
    };
  }, []);

  // Calculate scrollbar width and updates header padding
  useEffect(() => {
    const getScrollbarWidth = () => {
      if (bodyRef.current) {
        const scrollbarWidth = bodyRef.current.offsetWidth - bodyRef.current.clientWidth;
        console.log('🚀 ~ getScrollbarWidth ~ scrollbarWidth:', scrollbarWidth);
        setScrollbarWidth(scrollbarWidth);
      }
    };

    getScrollbarWidth();
    window.addEventListener('resize', getScrollbarWidth);
    return () => {
      window.removeEventListener('resize', getScrollbarWidth);
    };
  }, []);

  function onMouseDownResize(e: React.MouseEvent, colIndex: number) {
    // Prevent default text selection
    e.preventDefault();

    const startX = e.clientX;
    const startWidth = columnWidths[colIndex]

    function onMouseMove(ev: MouseEvent) {
      const delta = ev.clientX - startX;
      let newWidth = startWidth + delta;
      const minWidth = columns[colIndex].minWidth;
      const maxWidth = columns[colIndex].maxWidth;
      newWidth = Math.max(minWidth, Math.min(maxWidth, newWidth));
      // Update in state
      updateColumnWidth(colIndex, newWidth);
    }

    function onMouseUp() {
      // Cleanup
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  const bodyHeightCalc = `calc(100% - ${heightOffset + tableHeaderHeight + tableFooterHeight + columnHeaderHeight}px)`;

  return (
    <div
      style={{ height: `calc(100% - ${heightOffset}px)` }}
      className={cn("relative flex flex-col text-text-primary border border-border-table w-full")}
    >
      {/* Wrap tableHeader in a container with a ref to measure its height */}
      {tableHeader ? (
        <div ref={tableHeaderRef}>
          {tableHeader}
        </div>
      ) : null}

      <div
        className={cn(
          'column-header w-full overflow-scroll h-12 min-h-12 border-b border-border-table',
          'bg-bg-secondary transition-[background-color] ease-in-out duration-300'
        )}
        style={{ paddingRight: `${scrollbarWidth}px` }}
        ref={headerRef}
      >
        <div className="column-header h-12 min-h-12 w-fit min-w-full flex flex-row">
          {scrollbarWidth !== null && columns.map((column: Column, index) => (
            <div
              key={column.key}
              className={`px-2 flex flex-row items-center justify-between h-full w-fit`}
              style={{
                flex: column.fillSpace ? 1 : 'none',
                width: column.fillSpace ? 'unset' : columnWidths[index],
                minWidth: column.minWidth,
                maxWidth: column.fillSpace ? 'unset' : column.maxWidth,
              }}
            >
              <p className="text-sm text-text-primary font-normal">
                {column.name}
              </p>
              <div
                className="h-full w-[5px] cursor-col-resize flex justify-center items-center"
                onMouseDown={(e) => onMouseDownResize(e, index)}
              >
                <div className="h-2/3 w-[1px] bg-border-table" />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Height of the body is dynamically calculated based on the height of the tableHeader and tableFooter */}
      <div
        ref={bodyRef}
        style={{ minHeight: bodyHeightCalc, height: '100%' }}
        className="w-full h-fit overflow-auto divide-y divide-border-table"
      >
        {data ? (
          data.map((row, index) => (
            <div
              key={index}
              className="flex flex-row h-12 min-h-12 min-w-fit w-full cursor-pointer"
              onClick={() => onRowClick && onRowClick(row)}
            >
              {columns.map((column: Column, index) => (
                <div
                  key={column.key}
                  className={`flex flex-row items-center justify-start px-2 h-full`}
                  style={{
                    flex: column.fillSpace ? 1 : 'none',
                    width: column.fillSpace ? 'unset' : columnWidths[index],
                    minWidth: column.minWidth,
                    maxWidth: column.fillSpace ? 'unset' : column.maxWidth,
                  }}
                >
                  {column.renderComponent ? (
                    column.renderComponent({ row, index })
                  ) : (
                    <p className="text-sm text-text-primary font-normal">{getNestedValue(row, column.key)}</p>
                  )}
                </div>
              ))}
            </div>
          ))
        ) : isError ? (
          <div className="flex flex-row items-center justify-center h-full w-full">
            <p>Error fetching data</p>
          </div>
        ) : isFetching ? (
          <div className="flex flex-row items-center justify-center h-full w-full">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="flex flex-row items-center justify-center h-full">
            <p>No data available</p>
          </div>
        )}
      </div>
      {/* Wrap tableFooter in a container with a ref to measure its height */}
      <div ref={tableFooterRef} className="w-full">
        <PaginationFooter
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          total={total}
          pageNumbersOptions={pageNumbersOptions}
          variant={footerVariant}
          tableFooter={tableFooter}
        />
      </div>
    </div>
  );
};

export default Table;