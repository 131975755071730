import AnalyticsIcon from "components/common/Icons/AnalyticsIcon";
import DocumentArrowDownIcon from "components/common/Icons/DocumentArrowDownIcon";
import SettingsIcon from "components/common/Icons/SettingsIcon";
import AnalyticsPage from "components/pages/TruScan/Analytics";
import HomePage from "components/pages/Home";
import InvoicesPage from "components/pages/TruScan/Invoices";
import FullscreenInvoicePage from "components/pages/TruScan/Invoices/Fullscreen";
import ViewInvoicePage from "components/pages/TruScan/Invoices/View";
import KeysPage from "components/pages/TruScan/Settings/Keys";
import { ProductsNavigation, RouteConfig, Tabs } from "types";
import PureVuPage from "components/pages/PureVu";
import HomeIcon from "components/common/Icons/HomeIcon";
import PhotoIcon from "components/common/Icons/PhotoIcon";
import RocketIcon from "components/common/Icons/RocketIcon";

// The catch all route is used to redirect the user if the route does not exist
export const catchAllRoute = '/';
// Public routes are routes that do not require authentication
export const publicRoutes: string[] = [];

export const routes: RouteConfig[] = [
  {
    path: '/',
    component: HomePage,
    useLayout: true,
    isIndex: true,
  },
  {
    path: '/tru-scan/invoices',
    component: InvoicesPage,
    useLayout: true,
  },
  {
    path: '/tru-scan/invoices/:id',
    component: ViewInvoicePage,
    useLayout: true,
  },
  {
    path: '/tru-scan/invoices/:id/fullscreen',
    component: FullscreenInvoicePage,
    useLayout: false,
  },
  {
    path: '/tru-scan/analytics',
    component: AnalyticsPage,
    useLayout: true,
  },
  {
    path: '/tru-scan/settings',
    component: KeysPage,
    useLayout: true,
  },
  {
    path: '/tru-scan/settings/keys',
    component: KeysPage,
    useLayout: true,
  },
  {
    path: '/pure-vu',
    component: PureVuPage,
    useLayout: true,
  },
];

export const navigationTabs: Tabs[] = [
  {
    slugIdentifier: '/',
    tabs: [
      {
        label: 'Home',
        path: '/',
        icon: HomeIcon
      }
    ],
  },
  {
    slugIdentifier: 'tru-scan',
    tabs: [
      {
        label: 'Invoices',
        path: '/tru-scan/invoices',
        icon: DocumentArrowDownIcon
      },
      {
        label: 'Analytics',
        path: '/tru-scan/analytics',
        icon: AnalyticsIcon
      },
      {
        label: 'Settings',
        path: '/tru-scan/settings/keys',
        icon: SettingsIcon
      },
    ],
  },
  {
    slugIdentifier: 'pure-vu',
    tabs: [
      {
        label: 'Image Cleaner',
        path: '/pure-vu',
        icon: DocumentArrowDownIcon
      },
    ],
  },
];

export const productsNavigation: ProductsNavigation[] = [
  {
    label: (
      <span className='flex items-center'>
        <p className='text-lg text-white font-medium tracking-[0.4px]'>Tru</p>
        <p className='text-lg text-white font-bold tracking-[0.4px]'>Scan</p>
      </span>
    ),
    icon: RocketIcon,
    path: '/tru-scan/invoices'
  },
  {
    label: (
      <span className='flex items-center'>
        <p className='text-lg text-white font-medium tracking-[0.4px]'>Pure</p>
        <p className='text-lg text-white font-bold tracking-[0.4px]'>Vu</p>
      </span>
    ),
    icon: PhotoIcon,
    path: '/pure-vu'
  },
];