import NavigationBar from 'components/common/NavigationBar';
import { FC } from 'react'

export interface LayoutProviderProps {
  children: React.ReactNode
}

const LayoutProvider: FC<LayoutProviderProps> = ({ children }) => {
  return (
    <div className='relative flex flex-col items-start justify-start h-screen'>
      <NavigationBar />
      <main className='flex flex-col items-center justify-center w-full h-[calc(100%_-_64px)] overflow-y-auto'>
        {children}
      </main>
    </div>
  )
}

export default LayoutProvider