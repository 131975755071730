import CodeBracketIcon from "../Icons/CodeBracketIcon"
import LightBulbIcon from "../Icons/LightBulbIcon"
import ChatBubbleIcon from "../Icons/ChatBubbleIcon"
import { cn } from "utils"
import { useNavigate } from "react-router-dom"

const ResourcesOverview = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-fit-content">
      <p className='text-2xl text-text-primary font-semibold'>
        Resources for you
      </p>
      <div className='grid grid-cols-3 gap-4 mt-4'>
        {resources.map((resource, index) => (
          <div
            onClick={() => resource.link && navigate(resource.link)}
            key={index}
            className={cn(
              "flex items-center gap-4 p-4 bg-bg-secondary rounded-xl border border-border-secondary",
              "transition-[background-color] ease-in-out duration-300",
              resource.link && "cursor-pointer"
            )}
          >
            <resource.icon className='w-10 h-10 text-text-primary p-2 bg-bg-icon-main rounded-lg' />
            <div>
              <p className='text-text-primary font-semibold'>{resource.title}</p>
              <p className='text-text-secondary'>{resource.subtitle}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const resources = [
  {
    icon: CodeBracketIcon,
    title: 'API Documentation',
    subtitle: 'Read about our code',
    link: null,
  },
  {
    icon: LightBulbIcon,
    title: 'Knowledge Base',
    subtitle: 'Learn how to use our products',
    link: null,
  },
  {
    icon: ChatBubbleIcon,
    title: 'Contact Support',
    subtitle: 'Need help from our team?',
    link: null,
  },
]

export default ResourcesOverview