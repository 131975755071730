import { useState, useEffect } from 'react';

function useColumnWidths(
  tableKey: string,
  defaultWidths: number[]
) {
  const [columnWidths, setColumnWidths] = useState<number[]>([]);

  useEffect(() => {
    // On mount, check local storage for existing widths
    const saved = localStorage.getItem(tableKey);
    if (saved) {
      try {
        const parsed = JSON.parse(saved);
        if (Array.isArray(parsed) && parsed.length === defaultWidths.length) {
          setColumnWidths(parsed);
          return;
        }
      } catch (error) {
        console.error('Failed to parse local storage widths', error);
      }
    }
    // Otherwise use defaults
    setColumnWidths(defaultWidths);
  }, [tableKey]);

  // Function to update a specific column’s width
  function updateColumnWidth(colIndex: number, newWidth: number) {
    setColumnWidths((prev) => {
      const updated = [...prev];
      updated[colIndex] = newWidth;
      return updated;
    });
  }

  // Whenever columnWidths changes, store it in local storage
  useEffect(() => {
    if (columnWidths.length) {
      localStorage.setItem(tableKey, JSON.stringify(columnWidths));
    }
  }, [columnWidths, tableKey]);

  return { columnWidths, updateColumnWidth };
}

export default useColumnWidths;