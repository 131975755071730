import { createElement, useEffect, useMemo, useRef, useState } from 'react'
import { Avatar } from 'components/common/Avatar';
import QuestionMarkIcon from 'components/common/Icons/QuestionMarkIcon';
import NavTabs from 'components/common/NavTabs';
import { FC } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import DotsGridIcon from '../Icons/DotsGridIcon';
import { cn } from 'utils';
import { navigationTabs, productsNavigation } from 'utils/navigation';
import ZespyIcon from '../Icons/ZespyIcon';

const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentTabs = useMemo(() => {
    for (const tabGroup of navigationTabs) {
      const match = matchPath(`/${tabGroup.slugIdentifier}/*`, location.pathname);
      if (match) {
        return tabGroup.tabs;
      }
    }
    return [];
  }, [location.pathname]);

  return (
    <nav className='pl-4 h-16 bg-avatar-bg w-full flex items-center justify-between z-50 border-b border-border-table gap-4'>
      <ProductNavigation />
      <div className='px-4 h-16 bg-nav-bg w-full flex items-center justify-between rounded-tl-2xl'>
        <div className='flex items-center gap-4 h-full'>
          {location.pathname.includes('/tru-scan') ?
            <span className='flex items-center'>
              <p className='text-2xl text-white font-medium tracking-[0.4px]'>Tru</p>
              <p className='text-2xl text-white font-bold tracking-[0.4px]'>Scan</p>
            </span> :
            location.pathname.includes('/pure-vu') ?
              <span className='flex items-center'>
                <p className='text-2xl text-white font-medium tracking-[0.4px]'>Pure</p>
                <p className='text-2xl text-white font-bold tracking-[0.4px]'>Vu</p>
              </span> :
              <img src='/logo.svg' alt='logo' className='cursor-pointer h-10' onClick={() => navigate('/')} />
          }
          <NavTabs navigationTabs={currentTabs} />
        </div>
        <div className='flex items-center gap-4 h-full p-2'>
          <QuestionMarkIcon className='h-6 w-6 text-white cursor-pointer' />
          <Avatar />
        </div>
      </div>
    </nav>
  )
}

const ProductNavigation: FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  // Close dropdown when clicked outside
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setIsExpanded(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [])

  return (
    <div className='relative' ref={ref}>
      <div className={cn(
        'flex items-center cursor-pointer transition-all duration-300 ease-in-out',
        location.pathname === '/' ? 'w-8 gap-0' : 'w-16 gap-2'
      )}>
        <button onClick={() => navigate('/')} className='overflow-hidden'>
          <ZespyIcon className='h-6 w-6' />
        </button>
        <button onClick={() => setIsExpanded(!isExpanded)}>
          <DotsGridIcon className='h-8 w-8 min-w-8' />
        </button>
      </div>
      <div className={cn(
        'absolute top-10 left-0 w-[400px] bg-nav-bg rounded-lg shadow-lg z-50 border border-border-secondary flex-col p-6 gap-4',
        isExpanded ? 'flex' : 'hidden'
      )}>
        <p className="text-sm font-semibold text-text-supporting uppercase">Products</p>
        <div className='grid grid-cols-3 gap-4'>
          {productsNavigation.map((product, index) => (
            <button
              key={index}
              onClick={() => {
                setIsExpanded(false)
                navigate(product.path)
              }}
              className='text-white text-sm text-left w-[110px] flex flex-col gap-2 items-center p-4 justify-center'
            >
              {createElement(product.icon, { className: 'text-avatar-bg h-10 w-10' })}
              <span>{product.label}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default NavigationBar