import { FC } from 'react';
import { IconProps } from './types';

const PureVuUploadIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 160 120"
      className={className}
    >
      {/* Path 1 */}
      <path
        d="M103.22,66.69h0c8.09,0,14.66,6.56,14.66,14.66h0c0,8.09-6.56,14.66-14.66,14.66h0c-8.09,0-14.66-6.56-14.66-14.66h0c0-8.09,6.56-14.66,14.66-14.66Z"
        stroke="#7fd387"
        strokeWidth="2"
        fill="none"
      />

      {/* Circle */}
      <circle
        cx="63.42"
        cy="43.2"
        r="4.8"
        stroke="#7fd387"
        strokeWidth="2"
        fill="rgba(84, 196, 94, 0.08)"
      />

      {/* Path 2 */}
      <path
        d="M52.57,66.05c2.9-2.49,8.71-7.48,8.71-7.48,1.21-1.01,2.97-1,4.16.04l6.35,5.54c.32.28.79.26,1.09-.04l11.17-11.17c1.25-1.25,3.28-1.25,4.53,0,.08.08.16.17.23.25,0,0,8.33,10.57,10.27,13.04"
        stroke="#7fd387"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />

      {/* Path 4 */}
      <path
        d="M97.56,81.35l11.23.08M103.22,75.69l.08,11.23-.08-11.23Z"
        stroke="#7fd387"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />

      {/* Path 5 */}
      <path
        d="M109.72,68.23V26.19c0-1.22-.98-2.2-2.2-2.2h-63.2c-1.22,0-2.2.98-2.2,2.2v64c0,1.21.98,2.2,2.2,2.2h49.28"
        stroke="#7fd387"
        strokeWidth="2"
        fill="none"
      />

      {/* Path 6 */}
      <path
        d="M88.56,81.35c0-1.73.32-3.39.87-4.93h-38.71c-.77,0-1.4-.63-1.4-1.4v-42.4c0-.77.63-1.4,1.4-1.4h50.4c.77,0,1.4.63,1.4,1.4v34.11c.23-.01.46-.04.7-.04,1.32,0,2.59.19,3.8.52V28.73c0-1.1-.9-2-2-2h-58.21c-1.1,0-2,.9-2,2v58.92c0,1.1.9,2,2,2h44.33c-1.63-2.36-2.59-5.22-2.59-8.31Z"
        strokeWidth="2"
        fill="rgba(84, 196, 94, 0.08)"
        fillRule="evenodd"
      />

      {/* Path 7 */}
      <path
        d="M102.52,66.73v-34.11c0-.77-.63-1.4-1.4-1.4h-50.4c-.77,0-1.4.63-1.4,1.4v42.4c0,.77.63,1.4,1.4,1.4h38.71"
        stroke="#7fd387"
        strokeWidth="2"
        fill="none"
      />

      {/* Path 8 */}
      <path
        d="M99.65,67.15v-.74l-1.49-1.78-9.23-11.45c-1.19-1.48-3.41-1.6-4.75-.25l-11.17,11.17c-.3.3-.77.31-1.09.04l-6.35-5.54c-1.19-1.04-2.95-1.05-4.16-.04l-7.24,6.08-1.98,1.47v6.85c0,.44.36.53.8.53h37.89c1.99-3.11,5.11-5.41,8.78-6.33Z"
        strokeWidth="2"
        fill="rgba(84, 196, 94, 0.08)"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default PureVuUploadIcon;