import CheckCircle from 'components/common/Icons/CheckCircle';
import ExclamationTriangle from 'components/common/Icons/ExclamationTriangle';
import ExclamationCircle from 'components/common/Icons/ExclamationCircle';
import { FC, Fragment, useEffect, useState } from 'react';
import { InvoiceCheckKey, InvoiceValidation, InvoiceValidationChecksProps, MarkerProps } from './types';

const InvoiceValidationChecks: FC<InvoiceValidationChecksProps> = ({ currentInvoice }) => {
  const [currentInvoiceChecks, setCurrentInvoiceChecks] = useState<Record<InvoiceCheckKey, InvoiceValidation> | null>(null);

  useEffect(() => {
    const invoiceChecks = {
      "Invoice Date": {
        isValid: currentInvoice.validation.invoiceDate,
        isIntegrityCheck: false,
      },
      "Provider Name": {
        isValid: currentInvoice.validation.providerName,
        isIntegrityCheck: false,
      },
      "Provider ABN": {
        isValid: currentInvoice.validation.providerAbn,
        isIntegrityCheck: false,
      },
      "Participant Name": {
        isValid: currentInvoice.validation.participantName,
        isIntegrityCheck: false,
      },
      "Participant Number": {
        isValid: currentInvoice.validation.participantNumber,
        isIntegrityCheck: false,
      },
      "Product Codes": {
        isValid: currentInvoice?.validation.productCodeIntegrity,
        isIntegrityCheck: true,
      },
      "Total": {
        isValid: currentInvoice.validation.total,
        isIntegrityCheck: false,
      },
      "Total Calculation": {
        isValid: currentInvoice?.validation.totalsIntegrity,
        isIntegrityCheck: true,
      },
      "Line Item Calculation": {
        isValid: currentInvoice?.validation.lineItemIntegrity,
        isIntegrityCheck: true,
      },
    };
    setCurrentInvoiceChecks(invoiceChecks);
  }, [currentInvoice]);

  return (
    <div className="flex flex-row flex-wrap items-start justify-start gap-3 w-full">
      {currentInvoiceChecks && Object.entries(currentInvoiceChecks).map(([key, check]) => (
        <Fragment key={key} >
          {getMarker(check, key as InvoiceCheckKey)}
        </Fragment>
      ))}
    </div>
  )
};

const getMarker = (check: InvoiceValidation, text: InvoiceCheckKey) => {
  if (check.isValid && !check.isIntegrityCheck) {
    return <SuccessMarker text={text} />;
  } else if (check.isValid && check.isIntegrityCheck) {
    return <SuccessMarker text={text} />;
  } else if (!check.isValid && check.isIntegrityCheck) {
    return <MismatchMarker text={text} />;
  } else {
    return <WarningMarker text={text} />;
  }
}

const SuccessMarker: FC<MarkerProps> = ({ text }) => {
  return (
    <div className="flex items-center justify-center w-fit h-fit rounded-lg bg-success text-text-primary bg-bg-complete px-4 py-2 gap-2">
      <CheckCircle className="text-text-complete h-5 w-5" />
      <p className="text-xs font-semibold">
        {text}
      </p>
    </div>
  )
}

const WarningMarker: FC<MarkerProps> = ({ text }) => {
  return (
    <div className="flex items-center justify-center w-fit h-fit rounded-lg bg-bg-incomplete text-text-primary px-4 py-2 gap-2">
      <ExclamationCircle className="text-text-incomplete h-5 w-5" />
      <p className="text-xs font-semibold">
        {text}
      </p>
    </div>
  )
}

const MismatchMarker: FC<MarkerProps> = ({ text }) => {
  return (
    <div className="flex items-center justify-center w-fit h-fit rounded-lg bg-bg-mismatch text-text-primary px-4 py-2 gap-2">
      <ExclamationTriangle className="text-text-mismatch h-5 w-5" />
      <p className="text-xs font-semibold">
        {text}
      </p>
    </div>
  )
}

// const FailureMarker: FC<MarkerProps> = ({ text }) => {
//   return (
//     <div className="flex items-center justify-center w-fit h-fit rounded-lg bg-bg-failed text-text-primary px-4 py-2 gap-2">
//       <ErrorIcon className="text-text-failed h-5 w-5" />
//       <p className="text-xs font-semibold">
//         {text}
//       </p>
//     </div>
//   )
// }

export default InvoiceValidationChecks;