import { FC } from 'react'
import { IconProps } from './types'

const ZespyIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" className={className}>
      <g id="zespyLogo">
        <path id="Vector" d="M26.9228 6.08318C26.1898 6.89382 25.2069 7.98818 23.9673 9.36965C22.7277 10.7477 21.3969 12.2711 19.9749 13.9329C18.5529 15.5947 17.1208 17.3409 15.6785 19.1649C14.2363 20.9888 12.9257 22.7553 11.7469 24.4577H27.2301V32H0.769531V26.6464C1.6207 25.1872 2.64412 23.6166 3.83982 21.9312C5.03551 20.2491 6.29202 18.5535 7.61268 16.8512C8.92997 15.1488 10.2371 13.4972 11.5375 11.8928C12.8345 10.2918 14.0302 8.84273 15.128 7.54233H1.50248V0H26.9295V6.08318H26.9228Z" fill="#1F2937" />
      </g>
    </svg>
  )
}

export default ZespyIcon