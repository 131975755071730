import Button from "components/common/Button"
// import useCurrentAuthenticatedState from "hooks/useCurrentAuthenticatedState";

const Title = () => {
  // const [currentState] = useCurrentAuthenticatedState();

  return (
    <div className='w-full flex flex-row items-center justify-between border-b border-b-border-secondary pb-8'>
      <div className='flex flex-row items-center gap-2 h-full'>
        <span className='flex items-center'>
          <p className='text-2xl text-text-primary font-medium tracking-[0.4px]'>Pure</p>
          <p className='text-2xl text-text-primary font-bold tracking-[0.4px]'>Vu</p>
        </span>
        <div className='bg-border-secondary h-4 w-[1px] mx-2' />
        <p className='text-left text-text-secondary'>Image Cleaner</p>
      </div>
      <Button
        type="button"
        variant="primary"
        // TODO: When we have the product setup in state we will disable the button if the user is on a trial
        isDisabled={false}
      >
        Download
      </Button>
    </div>
  )
}

export default Title