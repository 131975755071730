import MonthPickerPopover from 'components/common/MonthPickerPopover'
import { useEffect, useState, useCallback } from 'react'
import ReactApexChart from 'react-apexcharts'
import { getChartOptions } from './options'
import { getInvoiceAggregates } from 'api/invoice'
import { LoadingIndicator } from 'components/common/FullscreenLoadingIndicator'
import { getUtcMonthRange } from 'utils'

// Apex Chart series placeholder
// The library does not support undefined data, so we need to provide a placeholder
const placeholderSeries = [
  { name: 'Complete', data: Array(30).fill(0) },
  { name: 'Incomplete', data: Array(30).fill(0) },
  { name: 'Failed', data: Array(30).fill(0) },
]

const AnalyticsChart = () => {
  const [month, setMonth] = useState<Date | undefined>(new Date())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [series, setSeries] = useState(placeholderSeries)
  const [totals, setTotals] = useState({
    Complete: 0,
    Incomplete: 0,
    Failed: 0,
  })

  const [monthRange, setMonthRange] = useState<{
    startOfMonthUTC: string
    endOfMonthUTC: string
  }>(() => getUtcMonthRange(new Date()))

  // Recompute the UTC month range if `month` changes
  useEffect(() => {
    const range = getUtcMonthRange(month)
    setMonthRange(range)
  }, [month])

  // This function fetches the data based on a start/end, then transforms to Apex format
  const fetchData = useCallback(
    async ({ startOfMonthUTC, endOfMonthUTC }: { startOfMonthUTC: string; endOfMonthUTC: string }) => {
      try {
        setIsLoading(true)

        // Fetch the data
        const resp = await getInvoiceAggregates(startOfMonthUTC, endOfMonthUTC)

        // Transform the data to the required format for ApexCharts
        const transformedData = resp.data?.map((item: any) => ({
          name: item.name,
          data: item.data?.map((point: any) => point.value) || [], // Fallback to an empty array if undefined
          dates: item.data?.map((point: any) => point.date) || [], // Fallback to an empty array if undefined
          color: getColor(item.name),
        })) || [];

        // Update the state
        setSeries(transformedData)
        setTotals(resp.totals);

      } catch (error) {
        console.error('Error fetching invoice aggregates:', error)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  // Whenever monthRange changes, fetch new data
  useEffect(() => {
    if (!monthRange.startOfMonthUTC || !monthRange.endOfMonthUTC) return;
    fetchData(monthRange);
  }, [monthRange, fetchData])

  // Options prop for the ApexChart component
  const chartOptions = getChartOptions(series)

  return (
    <div className="relative flex flex-col w-full h-full p-6 text-text-primary border border-border-table rounded-xl gap-10">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20 z-10 rounded-xl">
          <LoadingIndicator />
        </div>
      )}

      <div className="flex flex-row items-center justify-start w-full gap-6">
        <p className="text-lg font-semibold">Processed invoices by status</p>
        <MonthPickerPopover month={month} setMonth={setMonth} />
      </div>

      <div className="flex flex-col w-full h-full gap-4">
        <div className="w-full flex flex-row items-center justify-start gap-16">
          <div className="flex flex-col gap-1 items-start justify-start">
            <p className="text-2xl font-semibold">{totals.Complete}</p>
            <p className="text-sm font-semibold text-complete">Total Complete</p>
          </div>
          <div className="flex flex-col gap-1 items-start justify-start">
            <p className="text-2xl font-semibold">{totals.Incomplete}</p>
            <p className="text-sm font-semibold text-incomplete">Total Incomplete</p>
          </div>
          <div className="flex flex-col gap-1 items-start justify-start">
            <p className="text-2xl font-semibold">{totals.Failed}</p>
            <p className="text-sm font-semibold text-failed">Total Failed</p>
          </div>
        </div>

        <div className="relative w-full h-full">
          <ReactApexChart
            options={chartOptions}
            series={series}
            type="bar"
            height="100%"
            width="100%"
          />
        </div>
      </div>
    </div>
  )
}

function getColor(name: string) {
  switch (name.toLowerCase()) {
    case 'complete':
      return 'var(--complete)'
    case 'incomplete':
      return 'var(--incomplete)'
    case 'failed':
      return 'var(--failed)'
    default:
      return 'var(--default)'
  }
}

export default AnalyticsChart