import AddIcon from 'components/common/Icons/AddIcon';
import CloseIcon from 'components/common/Icons/CloseIcon';
import MinusIcon from 'components/common/Icons/MinusIcon';
import { cn, getImgSrcArray } from 'utils';
import { FC, useEffect, useState } from 'react';
import { LoadingIndicator } from 'components/common/FullscreenLoadingIndicator';
import { ViewInvoiceProps } from '../types';
import OpenPageIcon from 'components/common/Icons/OpenPageIcon';
import ImageWithZoom from 'components/common/ImageWithZoom';

const ViewInvoiceSection: FC<ViewInvoiceProps> = ({ currentInvoice }) => {
  const [scale, setScale] = useState(1);
  const [images, setImages] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const fullscreenUrl = `/tru-scan/invoices/${currentInvoice.publicId}/fullscreen`;

  useEffect(() => {
    // Fetch images of the current invoice
    const fetchImages = async () => {
      try {
        if (!currentInvoice) return;
        const imgSrcArray = await getImgSrcArray(currentInvoice.imageUrls);
        setImages(imgSrcArray);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching image URLs:', error);
      }
    };
    fetchImages();
  }, [currentInvoice]);

  // Handlers for zooming
  const handleZoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom at 3x
  const handleZoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom at 0.5x
  const handleResetZoom = () => setScale(1);

  if (isLoading) return (
    <div className='flex items-center justify-center w-full h-full'>
      <LoadingIndicator />
    </div>
  )

  return (
    <div className='flex flex-col items-start justify-start gap-4 relative w-full select-none'>
      <div className="flex flex-col items-start justify-start gap-4 relative h-full w-full">
        <div className='w-full flex flex-row justify-between p-6 pb-0'>
          <p className="text-lg text-text-primary font-semibold text-left w-full">
            Invoice Image
          </p>
          <a
            href={fullscreenUrl}
            target="_blank"
            rel="noreferrer"
            className='cursor-pointer'
          >
            <OpenPageIcon className='text-icon-main h-5 w-5' />
          </a>
        </div>
        {currentInvoice && currentInvoice.imageUrls.length > 0 ?
          <ImageWithZoom
            imgSrcArray={images}
            scale={scale}
            setScale={setScale}
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
            handleResetZoom={handleResetZoom}
            imagePadding='p-6 pt-0'
          /> :
          <p className="text-text-secondary text-sm text-left w-full p-8 pt-0">
            N/A
          </p>
        }
      </div>
      <div className="absolute bottom-4 right-4 flex flex-col gap-2">
        {scale !== 1 && (
          <button
            onClick={handleResetZoom}
            className={cn(
              "w-9 h-9 p-2 bg-bg-invoice-button rounded-full text-text-primary flex items-center justify-center text-xl",
              "shadow-[0px_4px_12px_rgba(31,41,55,0.10),0px_8px_16px_-8px_rgba(31,41,55,0.15)]"
            )}
          >
            <CloseIcon />
          </button>
        )}
        <button
          onClick={handleZoomIn}
          className={cn(
            "w-9 h-9 p-2 bg-bg-invoice-button rounded-full text-text-primary flex items-center justify-center text-xl",
            "shadow-[0px_4px_12px_rgba(31,41,55,0.10),0px_8px_16px_-8px_rgba(31,41,55,0.15)]"
          )}
        >
          <AddIcon />
        </button>
        <button
          onClick={handleZoomOut}
          className={cn(
            "w-9 h-9 p-2 bg-bg-invoice-button rounded-full text-text-primary flex items-center justify-center text-xl",
            "shadow-[0px_4px_12px_rgba(31,41,55,0.10),0px_8px_16px_-8px_rgba(31,41,55,0.15)]"
          )}
        >
          <MinusIcon />
        </button>
      </div>
    </div>
  )
};

export default ViewInvoiceSection;