import { cn } from "utils"
import { FC } from "react"
import { DisabledPaginationFooterProps, FullPaginationFooterProps, PaginationFooterProps } from "../types";
import ChevronLeftIcon from "components/common/Icons/ChevronLeftIcon";
import ChevronRightIcon from "components/common/Icons/ChevronRightIcon";
import CustomSelect from "components/common/CustomSelect";

const PaginationFooter: FC<PaginationFooterProps> = ({
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  total,
  pageNumbersOptions,
  variant = 'full',
  tableFooter
}) => {

  if (tableFooter) return tableFooter;

  if (variant === 'hidden') return <HiddenPaginationFooter />;

  if (variant === 'disabled') return (
    <DisabledPaginationFooter
      currentPage={currentPage}
      rowsPerPage={rowsPerPage}
      total={total}
    />
  )

  return (
    <FullPaginationFooter
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      total={total}
      pageNumbersOptions={pageNumbersOptions}
    />
  )
}

const FullPaginationFooter: FC<FullPaginationFooterProps> = ({
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  total,
  pageNumbersOptions,
}) => {

  const totalPages = Math.ceil(total / rowsPerPage);
  const indexOfLastInvoice = (currentPage + 1) * rowsPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - rowsPerPage;

  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
    setCurrentPage(0);
  };

  return (
    <div className={
      cn(
        "h-12 min-h-12 w-full border-t border-border-table bg-bg-secondary",
        "px-4 w-full flex items-center justify-between "
      )
    } >
      {/* Left Side */}
      <div className="flex items-center space-x-2" >
        <p className='text-xs text-text-secondary'>Rows per page:</p>
        <CustomSelect
          options={pageNumbersOptions.map(String)}
          onChange={handleRowsPerPageChange}
          value={String(rowsPerPage)}
          isDisabled={total === 0}
        />
        <p className='text-xs'>
          {total === 0 ? 'No records' : `Showing ${indexOfFirstInvoice + 1}-${Math.min(indexOfLastInvoice, total)} of ${total} records`}
        </p>
      </div>
      <div className="flex items-center space-x-1">
        <button
          className={`px-2 py-1 ${currentPage === 0 ? 'opacity-40' : 'cursor-pointer'}`}
          disabled={currentPage === 0}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <ChevronLeftIcon />
        </button>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            disabled={currentPage === page - 1}
            className={`px-2 py-1 rounded-sm ${currentPage === page - 1 ? 'bg-table-number-selected text-text cursor-default' : ''
              }`}
            onClick={() => setCurrentPage(page - 1)}
          >
            {page}
          </button>
        ))}
        <button
          className={cn(
            "px-2 py-1",
            currentPage === totalPages - 1 || total === 0 ? 'opacity-40' : 'cursor-pointer'
          )}
          disabled={currentPage === totalPages - 1 || total === 0}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <ChevronRightIcon />
        </button>
      </div>
    </div>
  )
};

const DisabledPaginationFooter: FC<DisabledPaginationFooterProps> = ({
  currentPage,
  rowsPerPage,
  total,
}) => {
  const indexOfLastInvoice = (currentPage + 1) * rowsPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - rowsPerPage;

  return (
    <div className={
      cn(
        "h-12 min-h-12 w-full border-t border-border-table bg-bg-secondary",
        "px-4 w-full flex items-center justify-between"
      )
    } >
      <div className="flex items-center space-x-2" >
        <p className='text-xs'>
          Showing {indexOfFirstInvoice + 1}-{Math.min(indexOfLastInvoice, total)} of {total} records
        </p>
      </div>
    </div>
  )
};


const HiddenPaginationFooter: FC = () => {
  return (
    <div className={
      cn(
        "h-12 min-h-12 w-full border-t border-border-table bg-bg-secondary",
        "px-4 w-full flex items-center justify-between"
      )
    } />
  )
};

export default PaginationFooter;